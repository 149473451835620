import {
    Box,
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Switch,
    Text
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import toast from 'react-hot-toast';
import Escrow from '../artifacts/contracts/Escrow.sol/Escrow.json';
import { getContract } from '../helpers/contract';

import { useWeb3React } from '@web3-react/core';
import { useTranslation } from 'react-i18next';
import { extractErrorCode } from '../helpers/helper';

export default function ActionModal({
    isOpen,
    onClose,
    status,
    address,
    isBuyer,
    alreadyRevised
}) {
    const [escrowStatus, setEscrowStatus] = useState(status);

    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const { chainId, account, activate, deactivate, active, library } =
        useWeb3React();
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [rejectDeadline, setRejectDeadline] = useState(tomorrow);
    const [testFiveMinEnabled, setTestFiveMinEnabled] = useState(false);

    useEffect(() => {
        setEscrowStatus(status);
    }, [status]);

    const getActions = (status, address, isBuyer) => {
        return (
            <Flex w={'100%'} direction={'column'} gap={2}>
                {!isBuyer && status === 'Launched' && approveButton(address)}
                {!isBuyer &&
                    status === 'Ongoing' &&
                    deliverSellerButton(address)}
                {isBuyer &&
                    status === 'Delivered' &&
                    buyerConfirmDeliveryOrRejectButtons(
                        address,
                        alreadyRevised
                    )}
                {!isBuyer &&
                    status === 'RequestRevised' &&
                    rejectDeliveryButtons(address)}
                {getCancellable(status) && cancelButton(address)}
            </Flex>
        );
    };

    const approveButton = (address) => {
        return (
            <Button
                colorScheme={'teal'}
                isLoading={loading}
                loadingText={t('pleaseWait')}
                onClick={() => sellerLaunchedApprove(address)}
            >
                {t('action-modal.Approve')}
            </Button>
        );
    };

    const cancelButton = (address) => {
        return (
            <Button
                isLoading={loading}
                loadingText={t('pleaseWait')}
                onClick={() => cancelEscrow(address)}
                colorScheme="red"
            >
                {t('action-modal.Cancel')}
            </Button>
        );
    };

    const deliverSellerButton = (address) => {
        return (
            <Button
                isLoading={loading}
                loadingText={t('pleaseWait')}
                onClick={() => sellerDeliver(address)}
                colorScheme="cyan"
            >
                {t('action-modal.Deliver')}
            </Button>
        );
    };

    const buyerConfirmDeliveryOrRejectButtons = (address, alreadyRevised) => {
        return (
            <>
                <form>
                    <Flex direction={'column'} gap={4}>
                        <Flex gap={2} align="center">
                            {process.env.REACT_APP_ENV_NAME !== 'prod' ? (
                                <Box textColor={'black'} color='black'>
                                    <Text>TEST 5 MIN</Text>
                                    <Switch
                                        bgColor={'gray.200'}
                                        value={testFiveMinEnabled}
                                        onChange={() =>
                                            setTestFiveMinEnabled(
                                                !testFiveMinEnabled
                                            )
                                        }
                                    />
                                </Box>
                            ) : (
                                <></>
                            )}
                            {!alreadyRevised ? (
                                <DatePicker
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholderText={`${t(
                                        'swap-form.Deadline'
                                    )} (yyyy-MM-dd)`}
                                    dateFormat="yyyy-MM-dd"
                                    minDate={tomorrow}
                                    onChange={(date) => setRejectDeadline(date)}
                                    selected={rejectDeadline}
                                />
                            ) : null}
                            <Button
                                w={'100%'}
                                isLoading={loading}
                                loadingText={t('pleaseWait')}
                                onClick={() => buyerDeliverReject(address)}
                                disabled={loading || !rejectDeadline}
                                colorScheme="red"
                            >
                                {!alreadyRevised
                                    ? t('action-modal.rejectDeliver')
                                    : t('states.Dispute')}
                            </Button>
                        </Flex>
                        <Box my={8}>
                            <Button
                                w={'100%'}
                                isLoading={loading}
                                loadingText={t('pleaseWait')}
                                onClick={() => buyerConfirmDelivery(address)}
                                colorScheme="orange"
                            >
                                {t('action-modal.confirmDeliver')}
                            </Button>
                        </Box>
                    </Flex>
                </form>
            </>
        );
    };

    const rejectDeliveryButtons = (address) => {
        return (
            <>
                <Button
                    isLoading={loading}
                    loadingText={t('pleaseWait')}
                    onClick={() => sellerRejectDeliverReject(address)}
                    colorScheme="red"
                >
                    {t('action-modal.rejectReviseRequest')}
                </Button>
                <Button
                    isLoading={loading}
                    loadingText={t('pleaseWait')}
                    onClick={() => sellerApproveDeliverReject(address)}
                    colorScheme="yellow"
                >
                    {t('action-modal.confirmReviseRequest')}
                </Button>
            </>
        );
    };

    const getCancellable = (status) => {
        return (
            status !== 'Dispute' &&
            status !== 'Cancelled' &&
            status !== 'Complete'
        );
    };

    const buyerConfirmDelivery = async (address) => {
        try {
            setLoading(true);
            const contract = getContract(library, account, Escrow, address);
            let delivery;
            //if (chainId === 88 || chainId === 89 || chainId === 31337) { // TODO: TOMO
            //    delivery = await contract.buyerConfirmDelivery({ gasPrice: 250000000, gasLimit: 1000000 });
            //} else {
                delivery = await contract.buyerConfirmDelivery();
            //}
            await delivery.wait();
            toast.success(t('Successful'));
            onClose();
        } catch (err) {
            toast.error(extractErrorCode(err, t));
        } finally {
            setLoading(false);
        }
    };

    const buyerDeliverReject = async (address) => {
        try {
            setLoading(true);
            const contract = getContract(library, account, Escrow, address);
            const difference = rejectDeadline.getTime() - new Date().getTime();
            let nextDeadline =
                Math.ceil(difference / (1000 * 3600 * 24)) * 86400;

            console.log('duration', nextDeadline);

            if (testFiveMinEnabled) {
                nextDeadline = 300;
            }

            const delivery = await contract.buyerDeliverReject(nextDeadline);

            await delivery.wait();
            toast.success(t('Successful'));
            onClose();
        } catch (err) {
            toast.error(extractErrorCode(err, t));
        } finally {
            setLoading(false);
        }
    };

    const sellerDeliver = async (address) => {
        try {
            setLoading(true);
            const contract = getContract(library, account, Escrow, address);
            const tsx = await contract.sellerDeliver();
            await tsx.wait();
            toast.success(t('Successful'));
            onClose();
        } finally {
            setLoading(false);
        }
    };

    const sellerRejectDeliverReject = async (address) => {
        try {
            setLoading(true);
            const contract = getContract(library, account, Escrow, address);
            const tsx = await contract.sellerRejectDeliverReject();
            await tsx.wait();
            toast.success(t('Successful'));
            onClose();
        } catch (err) {
            toast.error(extractErrorCode(err, t));
        } finally {
            setLoading(false);
        }
    };

    const sellerApproveDeliverReject = async (address) => {
        try {
            setLoading(true);
            const contract = getContract(library, account, Escrow, address);
            const tsx = await contract.sellerApproveDeliverReject();
            await tsx.wait();
            toast.success(t('Successful'));
            setEscrowStatus('Ongoing');
        } catch (err) {
            toast.error(extractErrorCode(err, t));
        } finally {
            setLoading(false);
        }
    };

    const sellerLaunchedApprove = async (address) => {
        try {
            setLoading(true);
            const contract = getContract(library, account, Escrow, address);
            const tsx = await contract.sellerLaunchedApprove();
            await tsx.wait();
            toast.success(t('Successful'));
            setEscrowStatus('Ongoing');
        } catch (err) {
            toast.error(extractErrorCode(err, t));
        } finally {
            setLoading(false);
        }
    };

    const cancelEscrow = async (address) => {
        try {
            setLoading(true);
            const contract = getContract(library, account, Escrow, address);
            let tsx;
            //if (chainId === 88 || chainId === 89 || chainId === 31337) { // TODO: TOMO
            //    tsx = await contract.cancel({ gasPrice: 250000000, gasLimit: 1000000 });
            //} else {
                tsx = await contract.cancel();
            //}

            await tsx.wait();
            toast.success(t('Successful'));
            onClose();
        } catch (err) {
            toast.error(extractErrorCode(err, t));
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
            <ModalOverlay />
            <ModalContent
                background="white"
                border="0.06rem"
                borderStyle="solid"
                borderColor="gray.300"
                borderRadius="3xl"
            >
                <ModalHeader
                    color="black"
                    px={4}
                    fontSize="lg"
                    fontWeight="medium"
                >
                    Actions
                </ModalHeader>
                <ModalCloseButton
                    color="black"
                    fontSize="sm"
                    _hover={{
                        color: 'gray.600'
                    }}
                />
                <ModalBody pt={0} px={4}>
                    <Box
                        borderRadius="3xl"
                        border="0.06rem"
                        borderStyle="solid"
                        borderColor="gray.300"
                        px={5}
                        pt={4}
                        pb={2}
                        mb={3}
                    >
                        <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            mb={3}
                        >
                            {getActions(escrowStatus, address, isBuyer)}
                        </Flex>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
