import { ChevronDownIcon, MoonIcon, SunIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Flex,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useColorMode
} from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { ethers } from 'ethers';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Web3 from 'web3';
import { SettingsContext } from '../App';
import { getContract } from '../helpers/contract';
import { getSelectedCoin } from '../helpers/helper';
import minimalAbi from '../helpers/minimalAbi.json';
import Identicon from './Identicon';

export default function ConnectButton({ handleOpenModal }) {
    const settings = useContext(SettingsContext);
    // TODO color mode disabled => const { colorMode, toggleColorMode } = useColorMode();

    const { chainId, account, activate, deactivate, active, library } =
        useWeb3React();
    const [etherBalance, setEtherBalance] = useState();

    const [chainSelect, setChainSelect] = useState();
    const [coinSelected, setCoinSelected] = useState();
    const [chainSelectRequest, setChainSelectRequest] = useState();

    const { t, i18n } = useTranslation();
    const [Coins, setCoins] = useState(settings.coinlist);

    const [bgColor, setBgColor] = useState('blue');

    useEffect(() => {
        if (window.ethereum && window.ethereum.networkVersion) {
            switchNetwork(window.ethereum.networkVersion);
        }
    }, []);

    useEffect(() => {
        if (settings && settings.settingsFromJson) {
            setBgColor(settings.settingsFromJson.BUTTON_COLOR);
        }
    }, [settings]);

    useEffect(() => {
        if (account) {
            calculateUserBalance();
        }
        if (chainId) {
            setChainSelect(chainId);
        }
    }, [account, chainId, coinSelected]);

    useEffect(() => {
        const symbol = settings.selectedCoin ? settings.selectedCoin : null;
        const coin = getSelectedCoin(Coins, chainId, symbol);
        setCoinSelected(coin);
    }, [chainId, settings.selectedCoin]);

    useEffect(() => {
        if (chainSelectRequest) {
            switchNetwork(chainSelectRequest);
        }
    }, [chainSelectRequest]);

    const switchNetwork = async (chainId) => {
        /**
         * 1. eth mainnet
         * 3. ropsten
         * 4. rinkeby
         * 56. bscmainnet
         * 89. tomochaintest
         * 88. tomo
         * 97. bsc testnet
         * 31337. localhost
         */
        const injected = new InjectedConnector({
            supportedChainIds: Object.entries(settings.chains)
                .map((opt, i) => {
                    if (
                        process.env.REACT_APP_ENV_NAME === 'prod' &&
                        !opt[1].showProd
                    ) {
                        return;
                    }
                    return Number(opt[0]);
                })
                .filter((e) => !!e)
        });

        if (!active) {
            await activate(injected);
        }

        if (!window.ethereum) {
            window.open('https://metamask.io/');
            return;
        }

        window.ethereum
            .request({
                method: 'wallet_switchEthereumChain',
                params: [
                    {
                        chainId: Web3.utils.toHex(chainId)
                    }
                ]
            })
            .catch((err) => {
                if (err.code === 4902) {
                    addNetwork(chainId);
                }
            });
    };

    const addNetwork = (chainId) => {
        const netw = settings.chains[chainId];
        window.ethereum
            .request({
                method: 'wallet_addEthereumChain',
                params: [
                    {
                        chainId: netw.chainId,
                        rpcUrls: netw.rpcUrls,
                        chainName: netw.chainName,
                        nativeCurrency: {
                            name: netw.nativeCurrencyName,
                            symbol: netw.nativeCurrencySymbol,
                            decimals: 18
                        },
                        blockExplorerUrls: netw.blockExplorerUrls
                    }
                ]
            })
            .catch(console.log);
    };

    const calculateUserBalance = async () => {
        if (!coinSelected || coinSelected.native) {
            const bal = await library.getBalance(account);
            const formatted = ethers.utils.formatEther(bal);
            setEtherBalance(formatted);
        } else {
            const contract = getContract(
                library,
                account,
                minimalAbi,
                coinSelected.address
            );
            const bal = await contract.balanceOf(account);
            const formatted = ethers.utils.formatEther(bal);
            setEtherBalance(formatted);
        }
    };

    return (
        <Flex
            alignItems='center'
            bg='rgb(247, 248, 250)'
            borderRadius='xl'
            gap={1}
        >
            <Flex
                direction={{ base: 'column', sm: 'row' }}
                alignItems='center'
                gap={{ base: 1, sm: 0 }}
            >
                <Flex>
                    {/*<Button cursor={'pointer'} onClick={toggleColorMode}>
                        {colorMode === 'light' ? (
                            <MoonIcon color='blue.700' />
                        ) : (
                            <SunIcon color='orange.400' />
                        )}
                    </Button>*/}
                    <Menu>
                        <MenuButton
                            colorScheme={bgColor}
                            borderRadius='1.12rem'
                            as={Button}
                            rightIcon={<ChevronDownIcon />}
                        >
                            {i18n.language.toUpperCase()}
                        </MenuButton>
                        <MenuList p={0}>
                            {/* Or map a list of options */}
                            <MenuItem onClick={() => {
                                i18n.changeLanguage('de');
                                localStorage.setItem('preferredLang', 'de');
                            }
                            }>
                                DE
                            </MenuItem>
                            <MenuItem onClick={() => {
                                i18n.changeLanguage('en');
                                localStorage.setItem('preferredLang', 'en');
                            }}>
                                EN
                            </MenuItem>
                            <MenuItem onClick={() => {
                                i18n.changeLanguage('tr');
                                localStorage.setItem('preferredLang', 'tr');
                            }}>
                                TR
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>

                {account && settings.chains ? (
                    <>
                        <Menu>
                            <MenuButton
                                colorScheme={bgColor}
                                borderRadius='1.12rem'
                                as={Button}
                                rightIcon={<ChevronDownIcon />}
                            >
                                {chainSelect
                                    ? settings.chains[chainSelect].chainName
                                    : 'Please Select Chain'}
                            </MenuButton>
                            <MenuList>
                                {process.env.REACT_APP_ENV_NAME !== 'prod'
                                    ? Object.entries(settings.chains)
                                        .sort((a, b) => a[1].showProd ? 1 : -1)
                                        .map((opt, i) => (
                                            <MenuItem
                                                key={i}
                                                onClick={() =>
                                                    setChainSelectRequest(
                                                        opt[0]
                                                    )
                                                }
                                            >
                                                <Image
                                                    src={opt[1].icon}
                                                    boxSize='1.5rem'
                                                    alt='Crypto Chain Logo'
                                                    mr='0.5rem'
                                                />
                                                {opt[1].chainName}
                                            </MenuItem>
                                        ))
                                    : Object.entries(settings.chains).map(
                                        (opt, i) =>
                                            opt[1].showProd && (
                                                <MenuItem
                                                    key={i}
                                                    onClick={() =>
                                                        setChainSelectRequest(
                                                            opt[0]
                                                        )
                                                    }
                                                >
                                                    <Image
                                                        src={opt[1].icon}
                                                        boxSize='1.5rem'
                                                        alt='Crypto Logo'
                                                        mr='0.5rem'
                                                    />
                                                    {opt[1].chainName}
                                                </MenuItem>
                                            )
                                    )}
                            </MenuList>
                        </Menu>
                        <Flex alignItems={'center'}>
                            <Box px='3'>
                                <Text color='black' fontSize='md'>
                                    {etherBalance &&
                                        parseFloat(etherBalance).toFixed(
                                            2
                                        )}{' '}
                                    {coinSelected && coinSelected.symbol}
                                </Text>
                            </Box>
                            <Button
                                onClick={handleOpenModal}
                                bg='white'
                                border='0.06rem solid transparent'
                                _hover={{
                                    border: '0.06rem',
                                    borderStyle: 'solid',
                                    borderColor: 'rgb(211,211,211)'
                                }}
                                borderRadius='xl'
                                m='0.06rem'
                                px={3}
                                h='2.37rem'
                            >
                                <Text
                                    color='black'
                                    fontSize='md'
                                    fontWeight='medium'
                                    mr='2'
                                >
                                    {account &&
                                        `${account.slice(
                                            0,
                                            6
                                        )}...${account.slice(
                                            account.length - 4,
                                            account.length
                                        )}`}
                                </Text>
                                <Identicon />
                            </Button>
                        </Flex>
                    </>
                ) : (
                    <Button
                        onClick={() =>
                            switchNetwork(
                                window.ethereum.networkVersion
                                    ? window.ethereum.networkVersion
                                    : process.env.REACT_APP_NETWORK_ID
                            )
                        }
                        variant={'solid'}
                        colorScheme={bgColor}
                        // bg={useColorModeValue(bgColor.light, bgColor.dark)}
                        fontSize='1rem'
                        fontWeight='semibold'
                        borderRadius='xl'
                        border='0.06rem solid rgb(253, 234, 241)'
                        _hover={{
                            borderColor: 'rgb(213, 0, 102)'
                        }}
                        _active={{
                            borderColor: 'rgb(213, 0, 102)'
                        }}
                    >
                        {t('header.connectWallet')}
                    </Button>
                )}
            </Flex>
        </Flex>
    );
}
