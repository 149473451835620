import { Tab } from '@headlessui/react';
import { useWeb3React } from '@web3-react/core';
import axios from 'axios';
import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import Escrow from '../artifacts/contracts/Escrow.sol/Escrow.json';
import EscrowFactory from '../artifacts/contracts/EscrowFactory.sol/EscrowFactory.json';
import erc20abi from '../artifacts/contracts/EscrowToken.sol/EscrowToken.json';
import escrowListingAbi from '../artifacts/contracts/EscrowListing.sol/EscrowListing.json';
import { Button } from './ui/Button';


import { getContract } from '../helpers/contract';

import {
    extractErrorCode,
    getCoinOptions,
    getCoinsForPriceRequest,
    getStringOfEnum, isValidAddress
} from '../helpers/helper';

import { Table } from './Table';

import toast from 'react-hot-toast';

import {
    Box,
    Center,
    filter,
    Flex,
    Image,
    Input, InputGroup, InputRightElement, Link,
    Menu,
    MenuButton, MenuItem, MenuList, Radio,
    Switch,
    Text,
    Button as ChakraButton,
    useDisclosure, Spacer, Divider
} from '@chakra-ui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsContext } from '../App';
import ActionModal from './ActionModal';
import Admin from './Admin';
import SwapForm from './SwapForm';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { ArrowDownIcon, ChevronDownIcon } from '@chakra-ui/icons';
import FilterGraph from './FilterGraph';
import EscrowListingAbi from '../artifacts/contracts/EscrowListing.sol/EscrowListing.json';

// const COINGECKO_FIRST_100 =
//     '/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false';

// COINGECKO 50/m
// BINANCE 1200 tsx per minute

const COINLIST_ID = 'coin-list';
const COIN_LAST_FETCH_TIME_ID = 'coin-last-fetch-time';

const ColumnHeaders = [
    'Address',
    'State',
    'Title',
    'Token',
    'Amount',
    'Deadline',
    'RequestRevisedDeadline',
    'Seller',
    'Buyer',
    'Action'
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

// export default function BuyerSellerAdminListing({settings}) {
export default function BuyerSellerAdminListing({ switchCreateValue }) {
    const { chainId, account, activate, deactivate, active, library } =
        useWeb3React();

    const settings = useContext(SettingsContext);

    const [selectedIndex, setSelectedIndex] = useState(1);
    const [page, setPage] = useState([]);

    const [factoryContract, setFactoryContract] = useState();
    const [optCoins, setOptCoins] = useState([]);
    const { t, i18n } = useTranslation();
    const [submitting, setSubmitting] = useState(false);
    const [coinOptions, setCoinOptions] = useState(
        getCoinOptions(settings.coinlist, chainId)
    );
    const [notMaticNetwork, setNotMaticNetwork] = useState(true);

    const [isAdmin, setIsAdmin] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [modalAttributes, setModalAttributes] = useState();
    const [escrowListingContract, setEscrowListingContract] = useState(null);

    const ColumnHeadersTranslated = ColumnHeaders.map((head) =>
        t(`column-headers.${head}`));

    useEffect(() => {
        if (chainId == 80001 || chainId == 137) {
            setNotMaticNetwork(false);
            callAndSetEscrowListingContract();
        } else {
            setNotMaticNetwork(true);
        }
    }, [chainId]);

    useEffect(() => {
        if (settings.currentAddress && settings.currentAddress.factoryAddr) {
            getFromContract(
                library,
                account,
                EscrowFactory,
                settings.currentAddress.factoryAddr
            );
            setCoinOptions(getCoinOptions(settings.coinlist, chainId));
        }
    }, [settings.currentAddress, account]);

    useEffect(() => {
        const lastFetchTime = localStorage.getItem(COIN_LAST_FETCH_TIME_ID);
        const items = localStorage.getItem(COINLIST_ID);
        const fetchTimeConfig =
            settings.settingsFromJson.COIN_PRICE_FETCH_TIME_AS_SECONDS;
        if (coinOptions) {
            if (
                lastFetchTime &&
                items &&
                (new Date() - lastFetchTime) / 1000 < fetchTimeConfig
            ) {
                setOptCoins(getCoinOpts(JSON.parse(items)));
            } else {
                callForCoinMarketCap();
            }
        }
    }, [factoryContract, coinOptions]);

    useEffect(() => {
        if (factoryContract) {
            checkAdmin();
            getMyEscrows();
        }
    }, [factoryContract]);

    const getFromContract = (library, account, abi, address) => {
        try {
            const contract = getContract(library, account, abi, address);
            console.log('FACT ADR', contract.address);
            setFactoryContract(contract);
        } catch (err) {
            console.log('err- get from contract appjs');
            toast.error(extractErrorCode(err, t));
        }
    };

    const callAndSetEscrowListingContract = async () => {
        try {
            const currentAddress = (
                await axios.get(
                    process.env.REACT_APP_ENV_NAME !== 'prod' ?
                        `data/contract-address-escrow-listing-test.json`
                        : `data/contract-address-escrow-listing.json`
                )
            ).data;

            const contract = getContract(library, account, escrowListingAbi, currentAddress.addr);
            setEscrowListingContract(contract);
        } catch (err) {
            console.log('err- get from contract escrow listing buyer seller');
            toast.error(extractErrorCode(err, t));
        }
    };

    const checkAdmin = async () => {
        try {
            const trustedHand = await factoryContract.checkTrusted(account);
            setIsAdmin(trustedHand);
        } catch (err) {
            console.log(err);
            setIsAdmin(false);
        }
    };

    const callForCoinMarketCap = async () => {
        try {
            // const response = { data: [] }; //await axios.get(COINGECKO_FIRST_100);

            // const aasd = await axios.get(COINGECKO_FIRST_100);
            const coinsForPrice = getCoinsForPriceRequest(settings.coinlist);
            const response = await axios({
                method: 'get',
                url: `https://api.coingecko.com/api/v3/simple/price?ids=${coinsForPrice.join(
                    ','
                )}&vs_currencies=usd`,
                timeout: 3000
            });

            setOptCoins(getCoinOpts(response.data));
            localStorage.setItem(COIN_LAST_FETCH_TIME_ID, new Date().getTime());
            localStorage.setItem(COINLIST_ID, JSON.stringify(response.data));
        } catch (err) {
            console.log('TIMEOUT=?', err);
            setOptCoins(coinOptions);
        }
    };

    const getCoinOpts = (data) => {
        return coinOptions.map((coin) => {
            if (!!coin.id && data[coin.id]) {
                return {
                    ...coin,
                    current_price: data[coin.id]['usd']
                };
            }
            return { ...coin };
        });
    };

    const getDetailObj = async (contract) => {
        const detail = await contract.getDetails();
        const title = ethers.utils.parseBytes32String(detail['title']);
        const amount = ethers.utils.formatEther(detail['amount'].toString());
        const buyer = detail['buyer'].toString();
        const address = detail['escrowAddress'].toString();
        const tokenAddr = detail['tokenAddress'].toString();
        const deadline = detail['deadline'].toString();
        const requestRevisedDeadline =
            detail['requestRevisedDeadline'].toString();

        const token = coinOptions
            .filter((coin) => coin.address === tokenAddr)
            .map((coin) => coin.symbol)
            .at(0);
        const seller = detail['seller'].toString();
        const st = detail['status'];
        const status = getStringOfEnum(st);
        const bal = await contract.getBalance();
        const balance = ethers.utils.formatEther(bal);

        return {
            address,
            status,
            amount,
            token,
            deadline,
            requestRevisedDeadline,
            buyer,
            seller,
            title,
            balance,
            actions: status !== 'Dispute' &&
                status !== 'Cancelled' &&
                status !== 'Complete' && (
                    <Button
                        text='ACTION'
                        onClick={() => {
                            const isBuyer = buyer === account;
                            setModalAttributes({ status, address, isBuyer, revised: requestRevisedDeadline > 0 });
                            onOpen();
                        }}
                        color={'bg-blue-600'}
                    />
                )
        };
    };

    const getMyEscrows = async () => {
        try {
            const myEscrows = await factoryContract.getMyEscrows();
            let escrows = [];
            let reverseOrder = [].concat(myEscrows).reverse();

            let escrowContractList = [];

            for (const eachEscrow of reverseOrder) {
                const contract = getContract(
                    library,
                    account,
                    Escrow,
                    eachEscrow
                );

                escrowContractList.push(contract);
                escrows.push(await getDetailObj(contract));
            }

            setPage(escrows);
        } catch (err) {
            console.log('ERR getMyEscrows buyer', err);
            toast.error(extractErrorCode(err, t));
        }
    };

    const createEscrow = async (data) => {
        setSubmitting(true);

        try {
            const tokenBuy = data.tokenAddress !== ethers.constants.AddressZero;
            if (tokenBuy) {
                const contract = getContract(
                    library,
                    account,
                    erc20abi,
                    data.tokenAddress
                );
                const approveTsx = await contract.approve(
                    settings.currentAddress.factoryAddr,
                    ethers.utils.parseUnits(data.amountCoin, 18)
                );
                await approveTsx.wait();
                toast.success('Token approved');
            }

            let options = {
                value: ethers.utils.parseUnits(data.amountCoin, 18)
            };

            let createEscrowTsx;

            if (tokenBuy) {
                createEscrowTsx = await factoryContract.createEscrow(
                    data.seller,
                    data.tokenAddress,
                    ethers.utils.parseEther(data.amountCoin),
                    ethers.utils.formatBytes32String(data.title),
                    data.duration
                );
            } else {
                createEscrowTsx = await factoryContract.createEscrow(
                    data.seller,
                    data.tokenAddress,
                    ethers.utils.parseEther(data.amountCoin),
                    ethers.utils.formatBytes32String(data.title),
                    data.duration,
                    options
                );
            }

            await createEscrowTsx.wait();
            toast.success('Escrow Successfully created.');
            setSelectedIndex((prevState) => prevState + 1);
        } catch (err) {
            console.log('createEscrow buyer');
            toast.error(extractErrorCode(err, t));
        } finally {
            setSubmitting(false);
        }
    };

    const handleCreateEscrow = (data) => {
        createEscrow(data);
    };

    const myHistory = () => {
        return (
            <div className='space-y-12'>
                <div className='flex-column w-full  mx-auto'>
                    <div className='flex w-full justify-center'>
                        <Table
                            columnHeaders={ColumnHeadersTranslated}
                            escrows={page}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const tabGroupWithAdmin = () => {
        return (
            <Tab.Group
                selectedIndex={selectedIndex}
                onChange={(i) => {
                    switchCreateValue(null);
                    setSelectedIndex(i);
                }}
            >
                <Tab.List className='flex space-x-1 rounded-xl bg-blue-900/20 p-1 pb-4'>
                    <Tab
                        className={({ selected }) =>
                            classNames(
                                'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                                selected
                                    ? 'bg-white shadow'
                                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                            )
                        }
                    >
                        Admin
                    </Tab>
                    <Tab
                        className={({ selected }) =>
                            classNames(
                                'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                                selected
                                    ? 'bg-white shadow'
                                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                            )
                        }
                    >
                        {t('marketplace')}
                    </Tab>
                    <Tab
                        className={({ selected }) =>
                            classNames(
                                'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                                selected
                                    ? 'bg-white shadow'
                                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                            )
                        }
                    >
                        {t('createEscrowTab')}
                    </Tab>
                    <Tab
                        className={({ selected }) =>
                            classNames(
                                'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                                selected
                                    ? 'bg-white shadow'
                                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                            )
                        }
                    >
                        {t('myProfileTab')}
                    </Tab>
                </Tab.List>
                <Tab.Panels>
                    <Tab.Panel
                        className={classNames(
                            'rounded-xl bg-white py-3',
                            'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                        )}
                    >
                        <Admin
                            factoryContractFrom={factoryContract}
                            listingContract={escrowListingContract}
                            coinOptions={coinOptions}
                        />
                    </Tab.Panel>
                    <Tab.Panel
                        className={classNames(
                            'rounded-xl py-3',
                            'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                        )}>
                        {notMaticNetwork ?
                            <Center> <Text ml={4} fontSize={32}>{t('unsupportedChainMarketplace')}</Text></Center>
                            :
                            <FilterGraph handleSwitch={(val) => switchToCreateTab(val)}
                                         contract={escrowListingContract} />
                        }

                    </Tab.Panel>
                    <Tab.Panel
                        className={classNames(
                            'rounded-xl bg-white py-3',
                            'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                        )}
                    >
                        {settings &&
                            optCoins &&
                            optCoins.length &&
                            account &&
                            library && (
                                <SwapForm
                                    library={library}
                                    account={account}
                                    coinlist={optCoins}
                                    createEscrowTrigger={handleCreateEscrow}
                                    submitting={submitting}
                                    dolarMinimum={
                                        settings.settingsFromJson
                                            .MIN_DOLLAR_PRICE
                                    }
                                />
                            )}
                    </Tab.Panel>

                    <Tab.Panel
                        className={classNames(
                            'rounded-xl bg-white py-3',
                            'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                        )}
                    >
                        {!library || !account || !settings.currentAddress ? (
                            <Center mt={16}>{t('unsupportedChain')}</Center>
                        ) : (
                            myHistory()
                        )}
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        );
    };

    const switchToCreateTab = (val) => {
        let x = {};
        Object.assign(x, val);
        x.seller = val.creator;
        switchCreateValue(x);
        setSelectedIndex(prev => prev + 1);
    };

    const tabGroup = () => {
        return (
            <Tab.Group
                selectedIndex={selectedIndex}
                onChange={(i) => {
                    switchCreateValue(null);
                    setSelectedIndex(i);
                }}
            >
                <Tab.List className='flex space-x-1 rounded-xl bg-blue-900/20 p-1'>
                    <Tab
                        className={({ selected }) =>
                            classNames(
                                'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                                selected
                                    ? 'bg-white shadow'
                                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                            )
                        }
                    >
                        {t('marketplace')}
                    </Tab>
                    <Tab
                        className={({ selected }) =>
                            classNames(
                                'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                                selected
                                    ? 'bg-white shadow'
                                    : 'text-blue-400 hover:bg-white/[0.12] hover:text-white'
                            )
                        }
                    >
                        {t('createEscrowTab')}
                    </Tab>
                    <Tab
                        className={({ selected }) =>
                            classNames(
                                'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                                selected
                                    ? 'bg-white shadow'
                                    : 'text-blue-400 hover:bg-white/[0.12] hover:text-white'
                            )
                        }
                    >
                        {t('myProfileTab')}
                    </Tab>
                </Tab.List>
                <Tab.Panels>
                    <Tab.Panel
                        className={classNames(
                            'rounded-xl py-3',
                            'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                        )}>
                        {notMaticNetwork ?
                            <Center> <Text ml={4} fontSize={32}>{t('unsupportedChainMarketplace')}</Text></Center>
                            :
                            <FilterGraph handleSwitch={(val) => switchToCreateTab(val)}
                                         contract={escrowListingContract} />
                        }

                    </Tab.Panel>
                    <Tab.Panel
                        className={classNames(
                            'rounded-xl py-3',
                            'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                        )}
                    >
                        {settings &&
                        settings.currentAddress &&
                        optCoins &&
                        optCoins.length &&
                        account &&
                        library ? (
                            <SwapForm
                                library={library}
                                account={account}
                                coinlist={optCoins}
                                createEscrowTrigger={handleCreateEscrow}
                                submitting={submitting}
                                dolarMinimum={
                                    settings.settingsFromJson.MIN_DOLLAR_PRICE
                                }
                                //  dolarMinimum={150}
                            />
                        ) : (
                            <></>
                        )}
                        {!library || !account || !settings.currentAddress ? (
                            <Center mt={16}>{t('unsupportedChain')}</Center>
                        ) : null}
                    </Tab.Panel>

                    <Tab.Panel
                        className={classNames(
                            'rounded-xl bg-white py-3',
                            'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                        )}
                    >
                        {!library || !account || !settings.currentAddress ? (
                            <Center mt={16}>{t('unsupportedChain')}</Center>
                        ) : (
                            myHistory()
                        )}
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        );
    };

    return (
        <div className='w-full mx-auto px-2 py-16 sm:px-0'>
            {modalAttributes && (
                <ActionModal
                    isOpen={isOpen}
                    onClose={onClose}
                    status={modalAttributes.status}
                    address={modalAttributes.address}
                    isBuyer={modalAttributes.isBuyer}
                    alreadyRevised={modalAttributes.revised}
                />
            )}
            {isAdmin && account && settings.currentAddress
                ? tabGroupWithAdmin()
                : tabGroup()}
        </div>
    );
}
