import { ethers } from 'ethers';

export const isValidAddress = (address) => ethers.utils.isAddress(address);

export const Statuses = [
    'Launched',
    'Ongoing',
    'RequestRevised',
    'Delivered',
    'Dispute',
    'Cancelled',
    'Complete'
];

export const getStringOfEnum = (index) => {
    return Statuses[index];
};

export const obfuscate = (address) => {
    return address.slice(0, 5) + '...' + address.slice(address.length - 3);
};

export const getCoinOptions = (Coins, chainId) => {
    const a = Coins.filter((coin) => coin.chainId === chainId).map((coin) => {
        if (!coin.address) {
            return { ...coin, address: ethers.constants.AddressZero };
        }
        return { ...coin };
    });
    return a;
};

export const getCoinsForPriceRequest = (Coins) => {
    var result = [];
    Coins.filter((coin) => !!coin.id).map((coin) => {
        if (!result.includes(coin.id)) {
            result.push(coin.id);
        }
    });
    return result;
};

export const getSelectedCoin = (Coins, chainId, selectedSymbol) => {
    const filtered = Coins.filter((coin) => coin.chainId === chainId);

    if (!selectedSymbol) {
        return filtered.find(coin => coin.native);
    }
    return filtered.find(coin => selectedSymbol === coin.symbol);
};

export const extractErrorCode = (error, t) => {
    console.error('IN ERROR', error);
    var str = error;
    if (!str) {
        return '';
    }

    if (error.reason) {
        str = error.reason.slice(0, 50) + '...';
    }

    if (!error.reason) {
        if (error.data.message) {
            return error.data.message.slice(0, 50) + '...';
        }
        return 'Internal Error please Contact site admin';
    }

    try {
        const delimiter = '___';
        const firstOccurence = str.indexOf(delimiter);
        const err = `An error occured: ${str}`;
        if (firstOccurence == -1) {
            return err;
        }

        const secondOccurence = str.indexOf(delimiter, firstOccurence + 1);
        if (secondOccurence == -1) {
            return err;
        }

        const parsed = str.substring(
            firstOccurence + delimiter.length,
            secondOccurence
        );

        return t(`contract-errors.${parsed}`);
    } catch (err) {
        console.log('HELPER extract errorcode', err);
        return '';
    }
};
