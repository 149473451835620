import {
    Box,
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay, Text
} from '@chakra-ui/react';
import React from 'react';
import { obfuscate } from '../helpers/helper';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';

export default function FilterActionModal({
                                              isOpen,
                                              onClose,
                                              escrow,
                                              handleSwitch
                                          }) {

    const { t } = useTranslation();

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size='md'>
            <ModalOverlay />
            <ModalContent
                background='white'
                border='0.06rem'
                borderStyle='solid'
                borderColor='gray.300'
                borderRadius='3xl'
            >
                <ModalHeader
                    color='black'
                    px={4}
                    fontSize='lg'
                    fontWeight='medium'
                >
                    {t('contactInformation')}
                </ModalHeader>
                <ModalCloseButton
                    color='black'
                    fontSize='sm'
                    _hover={{
                        color: 'gray.600'
                    }}
                />
                <ModalBody pt={0} px={4}>
                    <Box
                        borderRadius='3xl'
                        border='0.06rem'
                        borderStyle='solid'
                        borderColor='gray.300'
                        px={5}
                        pt={4}
                        pb={2}
                        mb={3}
                    >
                        <Flex
                            direction={'column'}
                            justifyContent='space-between'
                            alignItems='center'
                            mb={3}
                        >
                            <Text maxW={300} className='cursor-pointer' mb={2} color={'blue'} onClick={() =>
                                navigator.clipboard.writeText(
                                    escrow.creator
                                )
                            } data-tooltip-id='address-tooltip'
                                  data-tooltip-content={escrow.creator}>
                                <Tooltip id='address-tooltip' />{obfuscate(escrow.creator)}</Text>

                            {escrow.contactEmail && <Text maxW={380} color='black'
                                                          px={4}
                                                          fontSize='lg'
                                                          fontWeight='medium'>Mail: {escrow.contactEmail}</Text>}
                            {escrow.contactSocial && <Text maxW={380} color='black'
                                                           px={4}
                                                           fontSize='lg'
                                                           fontWeight='medium'>Social: {escrow.contactSocial}</Text>}
                            {escrow.category === 'Selling' && <Button mt={2} color={'white'}
                                                                      bg={'blue'}
                                                                      borderRadius='1.12rem'
                                                                      _hover={{ bg: 'rgb(207, 0, 99)' }}
                                                                      boxShadow='rgb(0 0 0 / 8%) 0rem 5.25rem 0.62rem'
                                                                      fontWeight='500'
                                                                      onClick={() => handleSwitch(escrow)}>{t('start')}</Button>}
                        </Flex>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
