import { Flex, Menu, Image, useColorMode } from '@chakra-ui/react';

import darkLogo from '!file-loader!../assets/logo.svg';
import lightLogo from '!file-loader!../assets/logo2.svg';

export default function Layout({ children }) {

    const { colorMode, toggleColorMode } = useColorMode();

    return (
        <Menu>
            <Flex
                d='flex'
                alignItems='center'
                justifyContent='space-between'
                mx='auto'
                mt='1.5rem'>
                <Image
                    boxSize={{ base: '3rem', sm: '4.56rem' }}
                    src={colorMode === 'dark' ? lightLogo : darkLogo}
                    alt='Logo' />
                {children}
            </Flex>
        </Menu>
    );
}
