import { extendTheme } from "@chakra-ui/react";


const config = {
  fonts: {
    heading: "Inter",
    body: "Inter",
  },
  initialColorMode: 'dark',
  //  If true, your app will change color mode based on the user's system preferences.
  useSystemColorMode: false,
}

const theme = extendTheme({ config })

export default theme

