import styled from '@emotion/styled';
import Jazzicon from '@metamask/jazzicon';
import { useWeb3React } from '@web3-react/core';
import { useEffect, useRef } from 'react';

const StyledIdenticon = styled.div`
    height: 1rem;
    width: 1rem;
    border-radius: 1.125rem;
    background-color: black;
`;

export default function Identicon() {
    const ref = useRef();
    const { chainId, account, activate, deactivate, active, library } =
        useWeb3React();

    useEffect(() => {
        if (account && ref.current) {
            ref.current.innerHTML = '';
            ref.current.appendChild(
                Jazzicon(16, parseInt(account.slice(2, 10), 16))
            );
        }
    }, [account]);

    return <StyledIdenticon ref={ref} />;
}
