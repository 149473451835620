import {
    Box,
    Button,
    Center,
    Flex,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Radio,
    Switch,
    Text,
    useBreakpointValue
} from '@chakra-ui/react';

import { ArrowDownIcon, ChevronDownIcon } from '@chakra-ui/icons';

import { ethers } from 'ethers';
import React, { useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SettingsContext } from '../App';
import erc20abi from '../artifacts/contracts/EscrowToken.sol/EscrowToken.json';
import { getContract } from '../helpers/contract';
import { isValidAddress } from '../helpers/helper';
import toast from 'react-hot-toast';

export default function SwapForm({
                                     coinlist,
                                     createEscrowTrigger,
                                     account,
                                     library,
                                     submitting,
                                     dolarMinimum = 0
                                 }) {
    const {
        register,
        handleSubmit,
        setValue,
        control,
        reset,
        formState: { errors }
    } = useForm();

    const { t, i18n } = useTranslation();

    const settings = useContext(SettingsContext);

    const [coinSelect, setCoinSelect] = useState(
        coinlist.filter((coin) => coin.native)[0].symbol
    );

    const [testFiveMinEnabled, setTestFiveMinEnabled] = useState(false);
    const [currentPrice, setCurrentPrice] = useState();
    const [userBalance, setUserBalance] = useState();
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [legalTextLink, setLegalTextLink] = useState();
    const [loading, setLoading] = useState(submitting);

    const [coinVal, setCoinVal] = useState(0.0);
    const [dolarVal, setDolarVal] = useState(dolarMinimum);
    const [coinMin, setCoinMin] = useState(0);
    const appendInvisibleDropdown = useBreakpointValue(
        {
            base: false,
            sm: true
        },
        {
            fallback: true
        }
    );

    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    useEffect(() => {
        if (settings.switchCreateValue) {
            reset({ ...settings.switchCreateValue });
            setDolarVal(settings.switchCreateValue.price);
            setValue('amountDolar', settings.switchCreateValue.price);
            calculateCoinAmount(settings.switchCreateValue.price);
        }
    }, []);

    useEffect(() => {
        if (settings && settings.settingsFromJson) {
            setLegalTextLink(settings.settingsFromJson.LEGAL_TEXT_LINK);
        }
    }, [settings]);

    useEffect(() => {
        if (coinSelect) {
            settings.coinSelectTrigger(coinSelect);
        }
    }, [coinSelect]);

    useEffect(() => {
        setLoading(submitting);
    }, [submitting]);

    useEffect(() => {
        if (coinlist && coinlist.length) {
            setCoinSelect(coinlist.filter((coin) => coin.native)[0].symbol);
        }
    }, coinlist);

    const onSubmit = (data) => {
        if (!termsAccepted) {
            toast.error(t('swap-form.TERMS_NOT_ACCEPTED_MESSAGE'));
            return;
        }

        const difference = data.duration
            ? data.duration.getTime() - new Date().getTime()
            : 0;

        data.duration = Math.ceil(difference / (1000 * 3600 * 24)) * 86400;
        if (testFiveMinEnabled) {
            data.duration = 300;
        }

        data.tokenAddress = coinlist
            .filter((coin) => coin.symbol === coinSelect)
            .map((coin) => coin.address)
            .at(0);
        createEscrowTrigger(data);
    };

    useEffect(() => {
        if (currentPrice) {
            calculateCoinAmount(dolarVal);
        }
    }, [currentPrice]);

    useEffect(() => {
        calculateUserBalance(
            coinlist
                .filter((coin) => coin.symbol === coinSelect)
                .map((coin) => coin.address)
                .at(0)
        );
    }, [account, coinlist]);

    useEffect(() => {
        if (coinSelect) {
            calculateUserBalance(
                coinlist
                    .filter((coin) => coin.symbol === coinSelect)
                    .map((coin) => coin.address)
                    .at(0)
            );
            calculateMinSymbol(coinSelect);
        }
    }, [coinSelect]);

    const calculateUserBalance = async (address) => {
        if (address === ethers.constants.AddressZero) {
            const bal = await library.getBalance(account);
            setUserBalance(ethers.utils.formatEther(bal));
        } else {
            const contract = getContract(library, account, erc20abi, address);
            const bal = await contract.balanceOf(account);
            setUserBalance(ethers.utils.formatEther(bal));
        }
    };

    const calculateDolarAmount = (coinAmount) => {
        if (coinAmount >= coinMin) {
            const val = Number(currentPrice * coinAmount).toFixed(4);
            setDolarVal(val);
            setValue('amountDolar', val);
        }
    };

    const calculateCoinAmount = (dolarAmount) => {
        if (dolarAmount >= dolarMinimum) {
            const val = Number(dolarAmount / currentPrice).toFixed(4);
            setCoinVal(val);
            setValue('amountCoin', val);
        }
    };

    const calculateMinSymbol = (symbol) => {
        const currentPrice = coinlist
            .filter((coin) => coin.symbol === symbol)
            .map((coin) => coin.current_price);
        setCurrentPrice(currentPrice);
        const min = dolarMinimum / currentPrice;
        setCoinMin(min);
    };

    return (
        <Box
            pt={2}
            mx='auto'
            boxShadow='rgb(0 0 0 / 8%) 0rem 0.37rem 0.62rem'
            borderRadius='1.37rem'
        >
            <Box p='0.5rem' bg='white' borderRadius='0 0 1.37rem 1.37rem'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Flex
                        my={1}
                        alignItems='center'
                        justifyContent='space-between'
                        bg='rgb(247, 248, 250)'
                        p='1rem 1rem 1.7rem'
                        borderRadius='1.25rem'
                        border={`0.2rem solid ${
                            errors.title ? 'red' : 'rgb(237, 238, 242)'
                        }`}
                        _hover={{ border: '0.2rem solid rgb(211,211,211)' }}
                    >
                        <Input
                            fontSize='1.5rem'
                            width='100%'
                            size='19rem'
                            textAlign='right'
                            bg='rgb(247, 248, 250)'
                            color={'black'}
                            outline='none'
                            border='none'
                            focusBorderColor='none'
                            type='text'
                            autoComplete='off'
                            placeholder={`${t(
                                'swap-form.Title'
                            )} (3 - 20 char)`}
                            aria-invalid={errors.title ? 'true' : 'false'}
                            {...register('title', {
                                required: true,
                                minLength: 3,
                                maxLength: 20
                            })}
                        />
                    </Flex>
                    <Flex
                        my={1}
                        alignItems='center'
                        justifyContent='space-between'
                        bg='rgb(247, 248, 250)'
                        p='1rem 1rem 1.7rem'
                        borderRadius='1.25rem'
                        border={`0.2rem solid ${
                            errors.seller ? 'red' : 'rgb(237, 238, 242)'
                        }`}
                        _hover={{ border: '0.2rem solid rgb(211,211,211)' }}
                    >
                        <Input
                            fontSize='1.5rem'
                            width='100%'
                            size='19rem'
                            textAlign='right'
                            bg='rgb(247, 248, 250)'
                            color={'black'}
                            outline='none'
                            border='none'
                            focusBorderColor='none'
                            type='text'
                            autoComplete='off'
                            placeholder={`*${t('swap-form.Seller')}`}
                            required
                            aria-invalid={errors.seller ? 'true' : 'false'}
                            {...register('seller', {
                                required: true,
                                validate: isValidAddress
                            })}
                        />
                    </Flex>

                    <Flex
                        my={1}
                        alignItems='center'
                        justifyContent='space-between'
                        bg='rgb(247, 248, 250)'
                        p='1rem 1rem 1.7rem'
                        borderRadius='1.25rem'
                        border={`0.2rem solid ${
                            errors.duration ? 'red' : 'rgb(237, 238, 242)'
                        }`}
                        _hover={{ border: '0.2rem solid rgb(211,211,211)' }}
                    >
                        {process.env.REACT_APP_ENV_NAME !== 'prod' ? (
                            <Box textColor={'black'} color='black'>
                                <Text>TEST 5 MIN</Text>
                                <Switch
                                    bgColor={'gray.200'}
                                    value={testFiveMinEnabled}
                                    onChange={() =>
                                        setTestFiveMinEnabled(
                                            !testFiveMinEnabled
                                        )
                                    }
                                />
                            </Box>
                        ) : (
                            <></>
                        )}
                        <Controller
                            control={control}
                            name='duration'
                            render={({ field }) => (
                                <DatePicker
                                    className='text-2xl text-right bg-transparent text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 align-right'
                                    minDate={tomorrow}
                                    placeholderText={`${t(
                                        'swap-form.Deadline'
                                    )} (yyyy-MM-dd)`}
                                    dateFormat='yyyy-MM-dd'
                                    onChange={(date) => field.onChange(date)}
                                    selected={field.value}
                                />
                            )}
                        />
                    </Flex>

                    <Flex
                        alignItems='center'
                        justifyContent='space-between'
                        bg='rgb(247, 248, 250)'
                        p='1rem 1rem 1.7rem'
                        borderRadius='1.25rem'
                        border={`0.2rem solid ${
                            errors.amountCoin ? 'red' : 'rgb(237, 238, 242)'
                        }`}
                        _hover={{ border: '0.2rem solid rgb(211,211,211)' }}
                    >
                        <Box>
                            <Menu>
                                <MenuButton
                                    borderRadius='1.12rem'
                                    bg='transparent'
                                    p='0'
                                    as={Box}
                                    _hover={{ bg: 'transparent' }}
                                    _focus={{ bg: 'transparent' }}
                                >
                                    <Button
                                        bg='white'
                                        borderRadius='1.12rem'
                                        _hover={{ bg: 'rgb(207, 0, 99)' }}
                                        boxShadow='rgb(0 0 0 / 8%) 0rem 5.25rem 0.62rem'
                                        fontWeight='500'
                                        rightIcon={
                                            <ChevronDownIcon
                                                fontSize='1.37rem'
                                                cursor='pointer'
                                            />
                                        }
                                    >
                                        {coinlist.find(
                                            (coin) => coin.symbol === coinSelect
                                        ) && (
                                            <Image
                                                src={
                                                    coinlist.find(
                                                        (coin) =>
                                                            coin.symbol ===
                                                            coinSelect
                                                    ).icon
                                                }
                                                boxSize='1.5rem'
                                                alt='Crypto Logo'
                                                mr='0.5rem'
                                            />
                                        )}
                                        <Text color={'black'}>
                                            {' '}
                                            {coinSelect}
                                        </Text>
                                    </Button>
                                </MenuButton>
                                <MenuList>
                                    {coinlist.map((opt, i) => (
                                        <MenuItem
                                            key={i}
                                            onClick={() =>
                                                setCoinSelect(opt.symbol)
                                            }
                                        >
                                            <Image
                                                src={opt.icon}
                                                boxSize='1.5rem'
                                                alt='Crypto Logo'
                                                mr='0.5rem'
                                            />
                                            {opt.symbol}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </Menu>
                        </Box>
                        <Box>
                            <InputGroup>
                                <Input
                                    placeholder={`*0,0 (Min ${coinMin.toFixed(
                                        2
                                    )})`}
                                    fontWeight='500'
                                    fontSize='1.5rem'
                                    width='100%'
                                    size='19rem'
                                    textAlign='right'
                                    bg='rgb(247, 248, 250)'
                                    outline='none'
                                    border='none'
                                    focusBorderColor='none'
                                    type='number'
                                    color={'black'}
                                    autoComplete='off'
                                    min={0}
                                    step='any'
                                    aria-invalid={
                                        errors.amountCoin ? 'true' : 'false'
                                    }
                                    {...register('amountCoin', {
                                        required: true,
                                        min: coinMin ? coinMin.toFixed(4) : 0
                                    })}
                                    value={coinVal}
                                    onChange={(e) => {
                                        setCoinVal(e.target.value);
                                        setValue('amountCoin', e.target.value);
                                        calculateDolarAmount(e.target.value);
                                    }}
                                />
                                <InputRightElement
                                    className='invisible'
                                    children='$'
                                />
                            </InputGroup>
                        </Box>
                    </Flex>

                    <Flex
                        alignItems='center'
                        justifyContent='space-between'
                        bg='rgb(247, 248, 250)'
                        pos='relative'
                        p='1rem 1rem 1.7rem'
                        borderRadius='1.25rem'
                        mt='0.25rem'
                        border={`0.2rem solid ${
                            errors.amountDolar ? 'red' : 'rgb(237, 238, 242)'
                        }`}
                        _hover={{ border: '0.2rem solid rgb(211,211,211)' }}
                    >
                        {appendInvisibleDropdown ? (
                            <>
                                <Box>
                                    <Button
                                        className='invisible'
                                        bg='rgb(232, 0, 111)'
                                        color='white'
                                        p='0rem 1rem'
                                        borderRadius='1.12rem'
                                        boxShadow='rgb(0 0 0 / 8%) 0rem 5.25rem 0.62rem'
                                        _hover={{ bg: 'rgb(207, 0, 99)' }}
                                        rightIcon={
                                            <ChevronDownIcon
                                                fontSize='1.37rem'
                                                cursor='pointer'
                                            />
                                        }
                                    >
                                        Select a token
                                    </Button>
                                </Box>
                                <Flex
                                    alignItems='center'
                                    justifyContent='center'
                                    bg='white'
                                    p='0.18rem'
                                    borderRadius='0.75rem'
                                    pos='relative'
                                    top='-2.37rem'
                                    left='2.5rem'
                                >
                                    <ArrowDownIcon
                                        bg='rgb(247, 248, 250)'
                                        color='rgb(128,128,128)'
                                        h='1.5rem'
                                        width='1.62rem'
                                        borderRadius='0.75rem'
                                    />
                                </Flex>
                            </>
                        ) : null}
                        <Box>
                            <InputGroup>
                                <Input
                                    placeholder={`*0,0 (Min ${dolarMinimum.toFixed(
                                        2
                                    )})`}
                                    fontSize='1.5rem'
                                    width='100%'
                                    size='19rem'
                                    textAlign='right'
                                    bg='rgb(247, 248, 250)'
                                    outline='none'
                                    border='none'
                                    focusBorderColor='none'
                                    color={'black'}
                                    type='number'
                                    autoComplete='off'
                                    min={0}
                                    step='any'
                                    aria-invalid={
                                        errors.amountDolar ? 'true' : 'false'
                                    }
                                    {...register('amountDolar', {
                                        required: true,
                                        min: dolarMinimum.toFixed(4)
                                    })}
                                    value={dolarVal}
                                    onChange={(e) => {
                                        setDolarVal(e.target.value);
                                        setValue('amountDolar', e.target.value);
                                        calculateCoinAmount(e.target.value);
                                    }}
                                />
                                <InputRightElement
                                    color={'black'}
                                    children='$'
                                />
                            </InputGroup>
                        </Box>
                    </Flex>

                    <Center>
                        <Flex py={6} pl={2} gap={4}>
                            <Box>
                                <Radio onChange={() => setTermsAccepted(!termsAccepted)} size='lg' bgColor={'gray.200'}
                                       colorScheme='green'>
                                    <Text textColor={'#D5035A'}>
                                        {t('swap-form.LEGAL_TEXT')}
                                    </Text>
                                </Radio>
                            </Box>
                            <Box pr={2}>
                                {legalTextLink ? (
                                    <Link
                                        href={legalTextLink}
                                        isExternal
                                    >
                                        <Button colorScheme={'blackAlpha'}>{t('swap-form.READ')}</Button>
                                    </Link>
                                ) : (
                                    <></>
                                )}
                            </Box>
                        </Flex>
                    </Center>

                    <Box mt='0.5rem'>
                        <Button
                            isLoading={loading}
                            loadingText={t('pleaseWait')}
                            color='rgb(213, 0, 102)'
                            bg='rgb(253, 234, 241)'
                            width='100%'
                            p='1.62rem'
                            borderRadius='1.25rem'
                            type='submit'
                            _hover={{ bg: 'rgb(251, 211, 225)' }}
                        >
                            {t('swap-form.CREATE_ESCROW')}
                        </Button>
                    </Box>
                </form>
            </Box>
        </Box>
    );
}
