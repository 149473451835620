import { Box, Center, Text, useDisclosure } from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import BuyerSellerAdminListing from './components/BuyerSellerAdminListing';
import ConnectButton from './components/ConnectButton';
import Header from './components/Header';

import '@fontsource/inter';

import axios from 'axios';
import { createContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import AccountModal from './components/AccountModal';
import { extractErrorCode } from './helpers/helper';

console.log('ENV_name', process.env.REACT_APP_ENV_NAME);

export const SettingsContext = createContext();

export default function App() {
    const coinSelect = (symbol) => {
        setSettings((prev) => ({ ...prev, selectedCoin: symbol }));
    };

    const [settings, setSettings] = useState({
        selectedCoin: null,
        coinSelectTrigger: (symbol) => coinSelect(symbol)
    });

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { account, chainId } = useWeb3React();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        call();
        const lang = localStorage.getItem('preferredLang');
        if (!lang) {
            localStorage.setItem('preferredLang', 'en')
            i18n.changeLanguage('en')
        }
    }, []);

    useEffect(() => {
        if (settings.chains && chainId) {
            callAddress(settings.chains, chainId);
        }
    }, [chainId]);

    /*useEffect(() => { TODO: enable after filter save localstorage
        refreshI18Json();
    }, [i18n.language]);*/

    const call = async () => {
        await callSettings();
    };

    const callAddress = async (chains, chainId) => {
        try {
            const currentAddress = (
                await axios.get(
                    `data/contract-address-${chains[chainId].name}.json`
                )
            ).data;
            console.log('!CURRENT_ADDR', currentAddress);
            setSettings((prev) => ({ ...prev, currentAddress }));
        } catch (err) {
            console.log('ERR, file not found!', err);
            toast.error(extractErrorCode(err, t));
            setSettings((prev) => ({ ...prev, currentAddress: null }));
        }
    };

    const setSwitchCreateValue = (switchCreateValue) => {
        setSettings((prev) => ({
            ...prev,
            switchCreateValue
        }));
    };

    const callSettings = async () => {
        const [chains, coinlist, settingsFromJson, filterValuesJson] = await Promise.all([
            axios.get('data/chains.json'),
            axios.get('data/coinlist.json'),
            axios.get('data/settings.json'),
            axios.get('data/filter-values.json')
        ]);

        setSettings((prev) => ({
            ...prev,
            chains: chains.data,
            coinlist: coinlist.data,
            settingsFromJson: settingsFromJson.data,
            filterCategories: filterValuesJson.data.categories,
            filterSubcategories: filterValuesJson.data.subcategories,
        }));
    };

    return (
        <SettingsContext.Provider value={settings}>
            <Box py={2} h='calc(100vh)'>
                <Box maxW={1500} minW={320} mx={'auto'} px={1}>
                    <Header>
                        {settings.chains ? (
                            <ConnectButton handleOpenModal={onOpen} />
                        ) : null}
                        {account ? (
                            <AccountModal isOpen={isOpen} onClose={onClose} />
                        ) : null}
                    </Header>
                    <div className='pb-16 w-100'>
                        {settings.coinlist && chainId ? (
                            <BuyerSellerAdminListing switchCreateValue={setSwitchCreateValue} />
                        ) : null}
                    </div>
                    {!account ? (
                        <Center>
                            <Text fontSize={32}>{t('unsupportedChain')}</Text>
                        </Center>
                    ) : null}
                </Box>
            </Box>
        </SettingsContext.Provider>
    );
}
