import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip'

import {
    Popover,
    PopoverArrow, PopoverBody, PopoverCloseButton,
    PopoverContent, PopoverHeader,
    PopoverTrigger,
    Table as CTable,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react';
import { obfuscate } from '../helpers/helper';
import { Button } from './ui/Button';

export const FilterTable = ({ columnHeaders, escrowFilters, handleOpenModal, isMyEscrow }) => {
    const { t, i18n } = useTranslation();

    return escrowFilters && escrowFilters.length ? (
        <TableContainer w={'100%'} px={1}>
            <CTable variant='simple'>
                <Thead>
                    <Tr>
                        {columnHeaders.map((header, i) => (
                            <Th key={i}>{header}</Th>
                        ))}
                    </Tr>
                </Thead>
                <Tbody textColor={'black'}>
                    {escrowFilters.map((escrow, i) => {
                        return (
                            <Tr key={i}>
                                <Td>{t(`filter-categories.${escrow.category}`)}</Td>
                                <Td>{t(`filter-listing-status.${escrow.escrowListingStatus}`)}</Td>
                                <Td
                                    className='cursor-pointer'
                                    onClick={() =>
                                        navigator.clipboard.writeText(
                                            escrow.creator
                                        )
                                    }
                                >
                                    {escrow.creator && obfuscate(escrow.creator)}
                                </Td>
                                <Td>{escrow.id}</Td>
                                <Td>{t(`filter-subcategories.${escrow.subcategory}`)}</Td>
                                <Td data-tooltip-id="my-tooltip"
                                    data-tooltip-content={escrow.title}>
                                    <Tooltip id="my-tooltip" />
                                    {escrow.title.length > 50 ? escrow.title.slice(0, 50) + '...' : escrow.title}</Td>
                                <Td>
                                    <div>{escrow.price} $</div>
                                </Td>
                                <Td>
                                    <Button
                                        text={isMyEscrow ? t('action') : t('contact')}
                                        onClick={() => handleOpenModal(escrow)}
                                        color={'bg-blue-600'}
                                    />
                                </Td>
                            </Tr>
                        );
                    })}
                </Tbody>
            </CTable>
        </TableContainer>
    ) : (
        <Text pl={2} textColor='black'>{t('noDataFound')}</Text>
    );
};
