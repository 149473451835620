import { extractErrorCode } from '../helpers/helper';
import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Button as ChakraButton,
    Center,
    Flex,
    Input,
    InputGroup,
    InputRightElement,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Radio,
    RadioGroup,
    Stack,
    Text,
    useDisclosure
} from '@chakra-ui/react';

import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { ApolloClient, gql, InMemoryCache } from '@apollo/client';
import { SettingsContext } from '../App';
import { useTranslation } from 'react-i18next';
import { FilterTable } from './FilterTable';
import FilterActionModal from './FilterActionModal';
import { Tab } from '@headlessui/react';
import { useWeb3React } from '@web3-react/core';
import FilterActionUpdateModal from './FilterActionUpdateModal';
import { ChevronDownIcon } from '@chakra-ui/icons';

const FILTERLIST_ID = 'filter-list';
const FILTER_LAST_FETCH_TIME_ID = 'filter-last-fetch-time';

const MY_FILTERLIST_ID = 'my-filter-list';
const FILTER_MY_LAST_FETCH_TIME_ID = 'my-filter-last-fetch-time';


let APIURL;

if (process.env.REACT_APP_ENV_NAME !== 'prod') {
    APIURL = 'https://api.thegraph.com/subgraphs/name/escrowdapp/escrow-dapp-filter';
} else {
    APIURL = 'https://gateway-arbitrum.network.thegraph.com/api/a16937d31db573d3c496099578a3605e/subgraphs/id/CyPyYUTiNgkYzhGXWrsYjNP4YVMLtFMqmbMKqFNPXXqe';
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const myTokensQuery = (address) => {
    return `
          query {
            escrowListings(orderBy: startedAt, orderDirection: desc, where: {
                creator: "${address}"
            }) {
              id
              escrowListingStatus
              category
              subcategory
              title
              price
              startedAt
              contactEmail
              contactSocial
              creator
            }
          }`;
};

// TODO disalbe scroll in all number inputs

const tokensQuery = (offset, limit, title, category, subcategory, minPrice, maxPrice) => {
    minPrice = minPrice ? minPrice : DEFAULT_MIN_PRICE;
    maxPrice = maxPrice ? maxPrice : DEFAULT_MAX_PRICE;
    const status = 0;
    title = title ? title : '';
    let query;
    if (category || subcategory) {
        if (category && !subcategory) {
            query = `
              query {
                escrowListings(first: ${limit}, skip: ${offset}, orderBy: startedAt, orderDirection: desc, where: {
                  price_gte: ${minPrice},
                  price_lte: ${maxPrice},
                  escrowListingStatus: ${status},
                  category: "${category}",
                  title_contains_nocase: "${title.trim()}"
                }) {
                  id
                  escrowListingStatus
                  category
                  subcategory
                  title
                  price
                  startedAt
                  contactEmail
                  contactSocial
                  creator
                }
              }`;
        } else if (!category && subcategory) {
            query = `
              query {
                escrowListings(first: ${limit}, skip: ${offset}, orderBy: startedAt, orderDirection: desc, where: {
                  price_gte: ${minPrice},
                  price_lte: ${maxPrice},
                  escrowListingStatus: ${status},
                  subcategory: "${subcategory}",
                  title_contains_nocase: "${title.trim()}"
                }) {
                  id
                  escrowListingStatus
                  category
                  subcategory
                  title
                  price
                  startedAt
                  contactEmail
                  contactSocial
                  creator
                }
              }`;
        } else {
            query = `
              query {
                escrowListings(first: ${limit}, skip: ${offset}, orderBy: startedAt, orderDirection: desc, where: {
                  price_gte: ${minPrice},
                  price_lte: ${maxPrice},
                  escrowListingStatus: ${status},
                  category: "${category}"
                  subcategory: "${subcategory}",
                  title_contains_nocase: "${title.trim()}"
                }) {
                  id
                  escrowListingStatus
                  category
                  subcategory
                  title
                  price
                  startedAt
                  contactEmail
                  contactSocial
                  creator
                }
              }`;
        }
    }

    if (!query) {
        query = `
          query {
            escrowListings(first: ${limit}, skip: ${offset}, orderBy: startedAt, orderDirection: desc, where: {
              price_gte: ${minPrice},
              price_lte: ${maxPrice},
              escrowListingStatus: ${status},
              title_contains_nocase: "${title.trim()}"
            }) {
              id
              escrowListingStatus
              category
              subcategory
              title
              price
              startedAt
              contactEmail
              contactSocial
              creator
            }
          }`;
    }

    return query;
};

const ColumnHeaders = [
    'Type',
    'State',
    'User ID',
    'Item ID',
    'Category',
    'Description',
    'Price',
    'Action'
];

const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache()
});

const DEFAULT_MIN_PRICE = 0;
const DEFAULT_MAX_PRICE = 9999999;

export default function FilterGraph({ contract, handleSwitch }) {
    const { account } = useWeb3React();

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        formState: { errors }
    } = useForm();

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        watch: watch2,
        formState: { errors: errors2 },
        getValues: getValues2,
        setValue: setValue2,
        reset: reset2
    } = useForm();

    const categoryWatch = watch('category');
    const subcategoryWatch = watch('subcategory');
    const socialWatch = watch('contactSocial');
    const emailWatch = watch('contactEmail');
    const subcategory2Watch = watch2('subcategory');
    const category2Watch = watch2('category');

    const settings = useContext(SettingsContext);
    const { t, i18n } = useTranslation();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [offset, setOffset] = useState(0);
    const [limit] = useState(5);

    const [termsAccepted, setTermsAccepted] = useState(false);
    const [legalTextLink, setLegalTextLink] = useState();
    const [filterValues, setFilterValues] = useState([]);
    const [myFilterValues, setMyFilterValues] = useState([]);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    const [escrow, setEscrow] = useState();
    const [escrowListing, setEscrowListing] = useState();
    const [selectedIndex, setSelectedIndex] = useState(0);

    //const [lang, setLang] = useState(i18n.language);

    const ColumnHeadersTranslated = ColumnHeaders.map((head) =>
        t(`column-headers.${head}`));

    /*useEffect(async () => {
        if (contract) {
            const paging = await contract.getEscrowListingDetailsPagingAll(0);
            console.log('!!!!PPPPPPP', paging);


            const paging1 = await contract.getEscrowListingDetailsPagingStatus(0, 1);
            console.log("!!!WTH STAUS=?", paging1.escrowListings)
        }
    }, [contract]);
    TODO: for direct contract usage w/o graph index
    */

    useEffect(() => {
        const lastFilterFetchTime = localStorage.getItem(FILTER_LAST_FETCH_TIME_ID);
        const filterItems = localStorage.getItem(FILTERLIST_ID);
        const filterFetchTimeConfig =
            settings.settingsFromJson.FILTER_FETCH_TIME_AS_SECONDS;

        /*if (
            lastFilterFetchTime &&
            //filterItems &&
            (new Date() - lastFilterFetchTime) / 1000 < filterFetchTimeConfig
        ) {
            setFilterValues(JSON.parse(filterItems));
        } else {*/
        callForFilterItems(getValues2()); // TODO: save query and response for cache
        //}

        /*if (
            lastFilterFetchTime &&
            //filterItems &&
            (new Date() - lastFilterFetchTime) / 1000 < filterFetchTimeConfig
        ) {
            setMyFilterValues(JSON.parse(filterItems));
        } else {*/
        callForMyFilterItems(account); // TODO: save query and response for cache
        //}

        // }, [filterInput, minPrice, maxPrice]);
    }, [account]);

    useEffect(() => {
        if (settings && settings.settingsFromJson) {
            setLegalTextLink(settings.settingsFromJson.LEGAL_TEXT_LINK);
        }
    }, [settings]);

    useEffect(() => {
        if (offset >= 0) {
            callForFilterItems(getValues2());
        }
    }, [offset]);

    useEffect(() => {
        setLoading(submitting);
    }, [submitting]);

    /*useEffect(() => {
        if (i18n.language !== lang) {
            window.location.reload(true);
            setLang(i18n.language);
        }
    }, [i18n.language]); TODO: added for dynamic json i18 files but not supported currently*/

    const resetFilter = () => {
        reset2();
        callForFilterItems({});
    };

    const createFilter = async (data) => {
        setSubmitting(true);
        data.contactEmail = !data.contactEmail ? '' : data.contactEmail;
        data.contactSocial = !data.contactSocial ? '' : data.contactSocial;
        try {
            let createEscrowTsx = await contract.appendItem(
                data.category,
                data.subcategory,
                data.title,
                data.price,
                data.contactEmail,
                data.contactSocial);

            await createEscrowTsx.wait();
            toast.success(t('createFilterSuccess'));
            setTimeout(() => {
                callForFilterItems(getValues2());
            }, 10000);
        } catch (err) {
            console.log('createEscrow buyer');
            toast.error(extractErrorCode(err, t));
        } finally {
            setSubmitting(false);
        }
    };

    const callForFilterItems = (data) => {
        setLoading(true);
        try {
            client
                .query({
                    query: gql(tokensQuery(offset, limit, data.title, data.category, data.subcategory, data.minPrice, data.maxPrice))
                })
                .then((res) => {
                    setFilterValues(res.data.escrowListings);
                    //localStorage.setItem(FILTER_LAST_FETCH_TIME_ID, new Date().getTime());
                    //localStorage.setItem(FILTERLIST_ID, JSON.stringify(res.data.escrowListings));
                })
                .catch((err) => {
                    console.log('Error fetching data: ', err);
                })
                .finally(() => setLoading(false));
        } catch (e) {
            console.log('TIMEOUT graph=?', e);
            setFilterValues([]);
        }
    };

    const callForMyFilterItems = (address) => {
        try {
            client
                .query({
                    query: gql(myTokensQuery(address))
                })
                .then((data) => {
                    setMyFilterValues(data.data.escrowListings);
                    // todo check local storage for filter and my
                    //localStorage.setItem(FILTER_MY_LAST_FETCH_TIME_ID, new Date().getTime());
                    //localStorage.setItem(MY_FILTERLIST_ID, JSON.stringify(data.data.escrowListings));
                })
                .catch((err) => {
                    console.log('Error fetching data: ', err);
                })
                .finally(() => setLoading(false));
        } catch (e) {
            console.log('TIMEOUT my graph=?', e);
            setMyFilterValues([]);
        }
    };

    const onSubmitFilterData = (data) => {
        callForFilterItems(data);
    };

    const onSubmit = (data) => {
        if (!termsAccepted) {
            toast.error(t('swap-form.TERMS_NOT_ACCEPTED_MESSAGE'));
            return;
        }
        createFilter(data);
    };

    const addListingForm = () => {
        return <form onSubmit={handleSubmit(onSubmit)}>
            {settings.filterCategories && <Menu
                placement={'bottom-end'}
                my={1}
                alignItems='center'
                justifyContent='space-between'
                bg='rgb(247, 248, 250)'
                p='1rem 1rem 1.7rem'
                borderRadius='1.25rem'
                border={`0.2rem solid ${
                    errors.category ? 'red' : 'rgb(237, 238, 242)'
                }`}
                _hover={{ border: '0.2rem solid rgb(211,211,211)' }}
            >
                <MenuButton fontSize='1.1rem'
                            width='100%'
                            size='12rem' w={'full'} bg='rgb(247, 248, 250)'
                            p='1rem 1rem 1.7rem'
                            borderRadius='1.25rem'
                            border={`0.2rem solid ${
                                errors.category ? 'red' : 'rgb(237, 238, 242)'
                            }`} color={'black'} as={Button} rightIcon={<ChevronDownIcon />}>
                    <Flex direction={'row-reverse'}>
                        {categoryWatch && <Text fontSize={20} ml={2}>{t(`filter-categories.${categoryWatch}`)}</Text>}
                        <Text fontSize={20} fontWeight={450} color={'rgb(156 163 175)'}>{t('me')} *</Text>
                    </Flex>


                </MenuButton>
                <MenuList {...register('category', { required: true })}>
                    {settings.filterCategories.map(val =>
                        <MenuItem key={val} onClick={() => {
                            setValue('subcategory', null);
                            setValue('category', val);
                        }}>{t(`filter-categories.${val}`)}</MenuItem>
                    )}
                </MenuList>
            </Menu>}

            {categoryWatch && <Menu
                placement={'bottom-end'}
                my={1}
                alignItems='center'
                justifyContent='space-between'
                bg='rgb(247, 248, 250)'
                p='1rem 1rem 1.7rem'
                borderRadius='1.25rem'
                border={`0.2rem solid ${
                    errors.subcategory ? 'red' : 'rgb(237, 238, 242)'
                }`}
                _hover={{ border: '0.2rem solid rgb(211,211,211)' }}
            >
                <MenuButton fontSize='1.1rem'
                            width='100%'
                            color={'black'}
                            size='12rem' w={'full'} bg='rgb(247, 248, 250)'
                            mt={1}
                            p='1rem 1rem 1.7rem'
                            borderRadius='1.25rem'
                            border={`0.2rem solid ${
                                errors.subcategory ? 'red' : 'rgb(237, 238, 242)'
                            }`} color={'black'} as={Button} rightIcon={<ChevronDownIcon />}>
                    <Flex direction={'row-reverse'}>
                        {subcategoryWatch && <Text fontSize={20} ml={2}>
                            {subcategoryWatch ? t(`filter-subcategories.${subcategoryWatch}`).slice(0, 10) :
                                t(`filter-subcategories.${subcategoryWatch}`)}{subcategoryWatch?.length > 10 ? '...' : ''}
                        </Text>}
                        <Text fontSize={20} fontWeight={450} color={'rgb(156 163 175)'}>{t('category')} *</Text>
                    </Flex>
                </MenuButton>
                <MenuList {...register('subcategory', { required: true })}>
                    {settings.filterSubcategories.map((subcategory, i) =>
                        <MenuItem key={i}
                                  onClick={() => setValue('subcategory', subcategory)}>{t(`filter-subcategories.${subcategory}`)}</MenuItem>
                    )}
                </MenuList>
            </Menu>}

            <Flex
                my={1}
                alignItems='center'
                justifyContent='space-between'
                bg='rgb(247, 248, 250)'
                p='1rem 1rem 1.7rem'
                borderRadius='1.25rem'
                border={`0.2rem solid ${
                    errors.title ? 'red' : 'rgb(237, 238, 242)'
                }`}
                _hover={{ border: '0.2rem solid rgb(211,211,211)' }}
            >
                <Input
                    fontSize='1.5rem'
                    width='100%'
                    size='19rem'
                    textAlign='right'
                    bg='rgb(247, 248, 250)'
                    color={'black'}
                    outline='none'
                    border='none'
                    focusBorderColor='none'
                    type='text'
                    autoComplete='off'
                    minLength={3}
                    maxLength={70}
                    placeholder={`${t(
                        'filter-form.Description'
                    )}* (3 - 70 char)`}
                    aria-invalid={errors.title ? 'true' : 'false'}
                    {...register('title', {
                        required: true,
                        minLength: 3,
                        maxLength: 70
                    })}
                />
            </Flex>

            <Flex
                my={1}
                alignItems='center'
                justifyContent='space-between'
                bg='rgb(247, 248, 250)'
                p='1rem 1rem 1.7rem'
                borderRadius='1.25rem'
                border={`0.2rem solid ${
                    errors.price ? 'red' : 'rgb(237, 238, 242)'
                }`}
                _hover={{ border: '0.2rem solid rgb(211,211,211)' }}
            >
                <InputGroup>
                    <Input
                        fontSize='1.5rem'
                        width='100%'
                        size='19rem'
                        textAlign='right'
                        bg='rgb(247, 248, 250)'
                        color={'black'}
                        outline='none'
                        border='none'
                        focusBorderColor='none'
                        type='number'
                        min={settings.settingsFromJson.MIN_DOLLAR_PRICE_FOR_FILTER}
                        autoComplete='off'
                        placeholder={`${t('price')} *`}
                        aria-invalid={errors.price ? 'true' : 'false'}
                        {...register('price', {
                            required: true,
                            min: {
                                value: settings.settingsFromJson.MIN_DOLLAR_PRICE_FOR_FILTER,
                                message: `Min ${settings.settingsFromJson.MIN_DOLLAR_PRICE_FOR_FILTER}`
                            }
                        })}
                    />
                    <InputRightElement
                        color={'black'}
                        children='$'
                    />
                </InputGroup>
            </Flex>

            <Box borderRadius={25} p={4} borderColor={'lightgray'} borderWidth={2}>
                <Flex direction={'row-reverse'}><Text fontSize={'2xl'}
                                                      color={'black'}>{t('contact')}</Text></Flex>

                <Flex
                    my={1}
                    alignItems='center'
                    justifyContent='space-between'
                    bg='rgb(247, 248, 250)'
                    p='1rem 1rem 1.7rem'
                    borderRadius='1.25rem'
                    border={`0.2rem solid ${
                        errors.contactEmail ? 'red' : 'rgb(237, 238, 242)'
                    }`}
                    _hover={{ border: '0.2rem solid rgb(211,211,211)' }}
                >
                    <Input
                        fontSize='1.5rem'
                        width='100%'
                        size='19rem'
                        textAlign='right'
                        bg='rgb(247, 248, 250)'
                        color={'black'}
                        outline='none'
                        border='none'
                        focusBorderColor='none'
                        type='email'
                        minLength={10}
                        maxLength={50}
                        autoComplete='off'
                        placeholder={`Email (10 - 50 char)`}
                        aria-invalid={errors.contactEmail ? 'true' : 'false'}
                        {...register('contactEmail', {
                            required: !socialWatch,
                            minLength: 10,
                            maxLength: 50
                        })}
                    />
                </Flex>

                <Flex
                    my={1}
                    alignItems='center'
                    justifyContent='space-between'
                    bg='rgb(247, 248, 250)'
                    p='1rem 1rem 1.7rem'
                    borderRadius='1.25rem'
                    border={`0.2rem solid ${
                        errors.contactSocial ? 'red' : 'rgb(237, 238, 242)'
                    }`}
                    _hover={{ border: '0.2rem solid rgb(211,211,211)' }}
                >
                    <Input
                        fontSize='1.5rem'
                        width='100%'
                        size='19rem'
                        textAlign='right'
                        bg='rgb(247, 248, 250)'
                        color={'black'}
                        outline='none'
                        border='none'
                        focusBorderColor='none'
                        type='text'
                        autoComplete='off'
                        minLength={10}
                        maxLength={50}
                        placeholder={`Social (10 - 50 char)`}
                        aria-invalid={errors.contactSocial ? 'true' : 'false'}
                        {...register('contactSocial', {
                            required: !emailWatch,
                            minLength: 10,
                            maxLength: 50
                        })}
                    />
                </Flex>
                <Text mx={4} align={'right'} color={'black'}><small>{t('contactHint')}</small></Text>
            </Box>
            <Center>
                <Flex py={6} pl={2} gap={4}>
                    <Box>
                        <Radio onChange={() => setTermsAccepted(!termsAccepted)}
                               size='lg' bgColor={'gray.200'} colorScheme='green'>
                            <Text textColor={'#D5035A'}>
                                {t('swap-form.LEGAL_TEXT')}
                            </Text>
                        </Radio>
                    </Box>
                    <Box pr={2}>
                        {legalTextLink ? (
                            <Link
                                href={legalTextLink}
                                isExternal
                            >
                                <ChakraButton
                                    colorScheme={'blackAlpha'}>{t('swap-form.READ')}</ChakraButton>
                            </Link>
                        ) : (
                            <></>
                        )}
                    </Box>
                </Flex>
            </Center>

            <Box mt='0.5rem'>
                <ChakraButton
                    isLoading={loading}
                    loadingText={t('pleaseWait')}
                    color='rgb(213, 0, 102)'
                    bg='rgb(253, 234, 241)'
                    width='100%'
                    p='1.62rem'
                    borderRadius='1.25rem'
                    type='submit'
                    _hover={{ bg: 'rgb(251, 211, 225)' }}
                >
                    {t('addListing')}
                </ChakraButton>

            </Box>
        </form>;
    };

    const filterListingForm = () => {
        return <form onSubmit={handleSubmit2(onSubmitFilterData)}>
            {settings.filterCategories && <Menu
                placement={'bottom-end'}
                my={1}
                alignItems='center'
                justifyContent='space-between'
                p='1rem 1rem 1.7rem'
                borderRadius='1.25rem'
                bg='rgb(247, 248, 250)'
                border={`0.2rem solid ${
                    errors2.category ? 'red' : 'rgb(237, 238, 242)'
                }`}
                _hover={{ border: '0.2rem solid rgb(211,211,211)' }}
            >
                <MenuButton fontSize='1.1rem'
                            width='100%'
                            size='12rem' w={'full'} bg='rgb(247, 248, 250)'
                            p='1rem 1rem 1.7rem'
                            borderRadius='1.25rem'
                            border={`0.2rem solid ${
                                errors2.category ? 'red' : 'rgb(237, 238, 242)'
                            }`} as={Button} rightIcon={<ChevronDownIcon />}>
                    <Flex direction={'row-reverse'}>
                        {category2Watch ? <Text color={'black'} fontSize={20} ml={2}>{t(`filter-categories.${category2Watch}`)}</Text> :
                            <Text fontSize={20} fontWeight={450}
                                  color={'rgb(156 163 175)'}>{t('categoryFilter')}</Text>}
                    </Flex>
                </MenuButton>
                <MenuList {...register2('category')}>
                    {settings.filterCategories.map(val =>
                        <MenuItem key={val} onClick={() => {
                            setValue2('subcategory', null);
                            setValue2('category', val);
                        }}>{t(`filter-categories.${val}`)}</MenuItem>
                    )}
                </MenuList>
            </Menu>}

            {category2Watch && <Menu
                placement={'bottom-end'}
                my={1}
                alignItems='center'
                justifyContent='space-between'
                bg='rgb(247, 248, 250)'
                p='1rem 1rem 1.7rem'
                borderRadius='1.25rem'
                border={`0.2rem solid ${
                    errors2.subcategory ? 'red' : 'rgb(237, 238, 242)'
                }`}
                _hover={{ border: '0.2rem solid rgb(211,211,211)' }}
            >
                <MenuButton fontSize='1.1rem'
                            width='100%'
                            color={'black'}
                            size='12rem' w={'full'} bg='rgb(247, 248, 250)'
                            mt={1}
                            p='1rem 1rem 1.7rem'
                            borderRadius='1.25rem'
                            border={`0.2rem solid ${
                                errors2.subcategory ? 'red' : 'rgb(237, 238, 242)'
                            }`} color={'black'} as={Button} rightIcon={<ChevronDownIcon />}>
                    <Flex direction={'row-reverse'}>
                        {subcategory2Watch &&
                            <Text fontSize={20} ml={2}>
                                {subcategory2Watch ? t(`filter-subcategories.${subcategory2Watch}`).slice(0, 10) :
                                    t(`filter-subcategories.${subcategory2Watch}`)}{subcategory2Watch?.length > 10 ? '...' : ''}
                            </Text>}
                        <Text fontSize={20} fontWeight={450} color={'rgb(156 163 175)'}>{t('category')} *</Text></Flex>
                </MenuButton>
                <MenuList {...register2('subcategory', { required: true })}>
                    {settings.filterSubcategories.map((subcategory, i) =>
                        <MenuItem key={i}
                                  onClick={() => setValue2('subcategory', subcategory)}>{t(`filter-subcategories.${subcategory}`)}</MenuItem>
                    )}
                </MenuList>
            </Menu>}


            <Flex
                my={1}
                alignItems='center'
                justifyContent='space-between'
                bg='rgb(247, 248, 250)'
                p='1rem 1rem 1.7rem'
                borderRadius='1.25rem'
                border={`0.2rem solid ${
                    errors2.minPrice ? 'red' : 'rgb(237, 238, 242)'
                }`}
                _hover={{ border: '0.2rem solid rgb(211,211,211)' }}
            >
                <InputGroup>

                    <Input
                        fontSize='1.5rem'
                        width='100%'
                        size='19rem'
                        textAlign='right'
                        bg='rgb(247, 248, 250)'
                        color={'black'}
                        outline='none'
                        border='none'
                        focusBorderColor='none'
                        type={'number'}
                        onWheelCapture={event => {
                            event.preventDefault();
                        }}
                        autoComplete='off'
                        min={settings.settingsFromJson.MIN_DOLLAR_PRICE_FOR_FILTER}
                        max={DEFAULT_MAX_PRICE}
                        {...register2('minPrice', {
                            min: {
                                value: settings.settingsFromJson.MIN_DOLLAR_PRICE_FOR_FILTER,
                                message: `Min ${settings.settingsFromJson.MIN_DOLLAR_PRICE_FOR_FILTER}`
                            }
                        })}
                        placeholder={`${t('minPrice')}`}
                        aria-invalid={errors2.minPrice ? 'true' : 'false'}
                    />
                    <InputRightElement
                        color={'black'}
                        children='$'
                    />
                </InputGroup>
            </Flex>

            <Flex
                my={1}
                alignItems='center'
                justifyContent='space-between'
                bg='rgb(247, 248, 250)'
                p='1rem 1rem 1.7rem'
                borderRadius='1.25rem'
                border={`0.2rem solid ${
                    errors2.maxPrice ? 'red' : 'rgb(237, 238, 242)'
                }`}
                _hover={{ border: '0.2rem solid rgb(211,211,211)' }}
            >
                <InputGroup>

                    <Input
                        fontSize='1.5rem'
                        width='100%'
                        size='19rem'
                        textAlign='right'
                        bg='rgb(247, 248, 250)'
                        color={'black'}
                        outline='none'
                        border='none'
                        focusBorderColor='none'
                        type={'number'}
                        min={settings.settingsFromJson.MIN_DOLLAR_PRICE_FOR_FILTER}
                        max={DEFAULT_MAX_PRICE}
                        autoComplete='off'
                        {...register2('maxPrice', {
                            min: settings.settingsFromJson.MIN_DOLLAR_PRICE_FOR_FILTER,
                            max: DEFAULT_MAX_PRICE
                        })}
                        placeholder={`${t('maxPrice')}`}
                        aria-invalid={errors2.maxPrice ? 'true' : 'false'}
                    />
                    <InputRightElement
                        color={'black'}
                        children='$'
                    />
                </InputGroup>
            </Flex>

            <Flex
                my={1}
                alignItems='center'
                justifyContent='space-between'
                bg='rgb(247, 248, 250)'
                p='1rem 1rem 1.7rem'
                borderRadius='1.25rem'
                border={`0.2rem solid ${
                    errors2.title ? 'red' : 'rgb(237, 238, 242)'
                }`}
                _hover={{ border: '0.2rem solid rgb(211,211,211)' }}
            >
                <Input
                    fontSize='1.5rem'
                    width='100%'
                    size='19rem'
                    textAlign='right'
                    bg='rgb(247, 248, 250)'
                    color={'black'}
                    outline='none'
                    border='none'
                    focusBorderColor='none'
                    type='text'
                    minLength={3}
                    maxLength={70}
                    autoComplete='off'
                    {...register2('title', { minLength: 3, maxLength: 70 })}
                    placeholder={`${t('detailsKeyword')}`}
                    aria-invalid={errors2.title ? 'true' : 'false'}
                />
            </Flex>
            <ChakraButton
                mb={2}
                isLoading={loading}
                loadingText={t('pleaseWait')}
                type={'submit'}
                color='rgb(213, 0, 102)'
                bg='lightblue'
                width='100%'
                p='1.62rem'
                borderRadius='1.25rem'
                _hover={{ bg: 'rgb(251, 211, 225)' }}
            >
                {t('filter')}
            </ChakraButton>
            <ChakraButton
                isLoading={loading}
                loadingText={t('pleaseWait')}
                onClick={() => resetFilter()}
                color='rgb(213, 0, 102)'
                bg='rgb(253, 234, 241)'
                width='100%'
                p='1.62rem'
                borderRadius='1.25rem'
                _hover={{ bg: 'rgb(251, 211, 225)' }}
            >
                {t('resetFilter')}
            </ChakraButton>

        </form>;
    };

    return (
        <Tab.Group
            selectedIndex={selectedIndex}
            onChange={(i) => setSelectedIndex(i)}
        >
            <Tab.List className='flex space-x-1 rounded-xl bg-blue-900/20 p-1'>
                <Tab
                    className={({ selected }) =>
                        classNames(
                            'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                            'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                            selected
                                ? 'bg-white shadow'
                                : 'text-blue-400 hover:bg-white/[0.12] hover:text-white'
                        )
                    }
                >
                    {t('allListings')}
                </Tab>
                <Tab
                    className={({ selected }) =>
                        classNames(
                            'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                            'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                            selected
                                ? 'bg-white shadow'
                                : 'text-blue-400 hover:bg-white/[0.12] hover:text-white'
                        )
                    }
                >
                    {t('myListings')}
                </Tab>
                <Tab
                    className={({ selected }) =>
                        classNames(
                            'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                            'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                            selected
                                ? 'bg-white shadow'
                                : 'text-blue-400 hover:bg-white/[0.12] hover:text-white'
                        )
                    }
                >
                    {t('addListing')}
                </Tab>
            </Tab.List>

            <Tab.Panels>
                <Tab.Panel
                    className={classNames(
                        'rounded-xl bg-white py-3',
                        'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                    )}
                >
                    {/* ALL LISTINGs */}
                    <Box
                        pt={2}
                        px={1}
                        mx='auto'
                        boxShadow='rgb(0 0 0 / 8%) 0rem 0.37rem 0.62rem'
                        borderRadius='1.37rem'
                    >
                        {filterListingForm()}
                        <Box textColor='black' p='0.5rem' bg='white' borderRadius='0 0 1.37rem 1.37rem'>
                            {escrow && <FilterActionModal
                                isOpen={isOpen}
                                onClose={onClose}
                                escrow={escrow}
                                handleSwitch={handleSwitch}
                            />}

                            <div className='space-y-12 mt-8'>
                                <div className='flex-column w-full  mx-auto'>
                                    <div className='flex w-full justify-center'>
                                        <FilterTable
                                            columnHeaders={ColumnHeadersTranslated}
                                            escrowFilters={filterValues}
                                            handleOpenModal={(escrow) => {
                                                setEscrow(escrow);
                                                onOpen();
                                            }}
                                            isMyEscrow={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='flex-column w-full mx-auto mt-4'>
                                <div className='flex flex-col items-center'>
                                    <div className='inline-flex xs:mt-0 space-x-1'>
                                        <button
                                            disabled={offset <= 0}
                                            onClick={() => setOffset((prev) => prev - limit)}
                                            className='py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-l hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                                        >
                                            {loading ? t('pleaseWait') : t('prev')}
                                        </button>
                                        <button
                                            disabled={!filterValues.length}
                                            onClick={() => setOffset((prev) => prev + limit)}
                                            className='py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-r border-0 border-l border-gray-700 hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                                        >
                                            {loading ? t('pleaseWait') : t('next')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Box>
                </Tab.Panel>

                <Tab.Panel className={classNames(
                    'rounded-xl bg-white py-3',
                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                )}>
                    {/* MY LISTING */}
                    <>
                        {escrowListing && <FilterActionUpdateModal
                            settings={settings}
                            isOpen={isOpen}
                            onClose={onClose}
                            escrowListing={escrowListing}
                            contract={contract}
                        />}
                        <div className='space-y-12 mt-8'>
                            <div className='flex-column w-full  mx-auto'>
                                <div className='flex w-full justify-center'>
                                    <FilterTable
                                        columnHeaders={ColumnHeadersTranslated}
                                        escrowFilters={myFilterValues}
                                        handleOpenModal={(escrow) => {
                                            setEscrowListing(escrow);
                                            onOpen();
                                        }}
                                        isMyEscrow={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </>

                </Tab.Panel>
                <Tab.Panel className={classNames(
                    'rounded-xl bg-white py-3',
                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                )}>
                    {/* ADD LISTING */}
                    <Box px={1}>
                        {addListingForm()}
                    </Box>

                </Tab.Panel>

            </Tab.Panels>
        </Tab.Group>
    );
}
