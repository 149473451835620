export const Button = ({ type = 'button', color = 'bg-green-500', text, onClick, disabled = false }) => {
  return (
    <button
        className={`px-4 py-2 text-sm font-medium text-white ${color} rounded-md hover:bg-opacity-50 duration-300 shadow-xl`}
        disabled={disabled}
        onClick={onClick}
        type={type}
      >
        {text}
      </button>
  );
};
