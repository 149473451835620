import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    Table as CTable,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react';
import { obfuscate } from '../helpers/helper';

export const Table = ({ columnHeaders, escrows }) => {
    const { t, i18n } = useTranslation();

    return escrows && escrows.length ? (
        <TableContainer w={'100%'} px={1}>
            <CTable variant="simple">
                <Thead>
                    <Tr>
                        {columnHeaders.map((header, i) => (
                            <Th key={i}>{header}</Th>
                        ))}
                    </Tr>
                </Thead>
                <Tbody textColor={'black'}>
                    {escrows.map((escrow, i) => {
                        return (
                            <Tr key={i}>
                                 <Td
                                    className="cursor-pointer"
                                    onClick={() =>
                                        navigator.clipboard.writeText(
                                            escrow.address
                                        )
                                    }
                                >
                                    {escrow.address && obfuscate(escrow.address)}
                                </Td>
                                <Td>{t(`states.${escrow.status}`)}</Td>
                                <Td>{escrow.title}</Td>
                                <Td>{escrow.token}</Td>
                                <Td>{escrow.amount}</Td>
                                <Td>
                                    {new Date(escrow.deadline * 1000)
                                        .toISOString()
                                        .slice(0, 10)}
                                </Td>
                                <Td>
                                    {escrow.status === 'RequestRevised' &&
                                        escrow.requestRevisedDeadline &&
                                        new Date(
                                            escrow.requestRevisedDeadline * 1000
                                        )
                                            .toISOString()
                                            .slice(0, 10)}
                                </Td>
                                <Td
                                    className="cursor-pointer"
                                    onClick={() =>
                                        navigator.clipboard.writeText(
                                            escrow.seller
                                        )
                                    }
                                >
                                    {escrow.seller && obfuscate(escrow.seller)}
                                </Td>
                                <Td
                                    className="cursor-pointer"
                                    onClick={() =>
                                        navigator.clipboard.writeText(
                                            escrow.buyer
                                        )
                                    }
                                >
                                    {escrow.buyer && obfuscate(escrow.buyer)}
                                </Td>
                                <Td>
                                    <div>{escrow.actions}</div>
                                </Td>
                            </Tr>
                        );
                    })}
                </Tbody>
            </CTable>
        </TableContainer>
    ) : (
        <Text pl={2} textColor='black'>{t('noDataFound')}</Text>
    );
};
