import {
    Box,
    Button,
    Center,
    Flex,
    Input,
    InputGroup,
    InputRightElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Radio,
    RadioGroup,
    Stack,
    Text
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { extractErrorCode } from '../helpers/helper';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import FormFieldError from './ui/formFieldError';
import { ChevronDownIcon } from '@chakra-ui/icons';

export default function FilterActionUpdateModal({
                                                    settings,
                                                    isOpen,
                                                    onClose,
                                                    escrowListing,
                                                    contract
                                                }) {
    const {
        register,
        handleSubmit,
        watch,
        reset,
        setValue,
        formState: { errors }
    } = useForm();

    const {
        register: registerStatusUpdate,
        handleSubmit: handleSubmitStatusUpdate,
        watch: watchStatusUpdate,
        formState: { errors: errorsStatusUpdate }
    } = useForm();

    const { t, i18n } = useTranslation();

    const categoryWatch = watch('category');
    const subcategoryWatch = watch('subcategory');
    const emailWatch = watch('contactEmail');
    const socialWatch = watch('contactSocial');
    const [loading, setLoading] = useState(false);

    const onSubmit = async (data) => {
        setLoading(true);
        data.contactEmail = !data.contactEmail ? '' : data.contactEmail;
        data.contactSocial = !data.contactSocial ? '' : data.contactSocial;
        try {
            let updateEscrowTsx = await contract.updateItem(
                data.id,
                data.escrowListingStatus,
                data.category,
                data.subcategory,
                data.title,
                data.price,
                data.contactEmail,
                data.contactSocial);

            await updateEscrowTsx.wait();
            toast.success('Filter Successfully updated.');
        } catch (err) {
            console.log('Filter update error', err);
            toast.error(extractErrorCode(err, t));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (escrowListing) {
            reset(escrowListing);
        }
    }, [escrowListing]);

    const changeStatus = async (data) => {
        setLoading(true);
        try {
            const updateStatus = await contract.updateStatus(escrowListing.id, data.status);
            await updateStatus.wait();
            toast.success('Status successfully changed.');
        } catch (e) {
            console.error(e);
            toast.error(extractErrorCode(e, t));
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent
                background='white'
                border='0.06rem'
                borderStyle='solid'
                borderColor='gray.300'
                borderRadius='xl'
            >
                <ModalHeader
                    color='black'
                    px={4}
                    fontSize='2xl'
                    fontWeight='medium'
                >
                    {t('updateListing')}
                </ModalHeader>
                <ModalCloseButton
                    color='black'
                    fontSize='sm'
                    _hover={{
                        color: 'gray.600'
                    }}
                />
                <ModalBody px={4}>
                    <Box
                        px={1}
                        pb={2}
                        mb={3}
                    >
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {settings.filterCategories && <Menu
                                placement={'bottom-end'}
                                my={1}
                                alignItems='center'
                                justifyContent='space-between'
                                bg='rgb(247, 248, 250)'
                                p='1rem 1rem 1.7rem'
                                borderRadius='1.25rem'
                                border={`0.2rem solid ${
                                    errors.category ? 'red' : 'rgb(237, 238, 242)'
                                }`}
                                _hover={{ border: '0.2rem solid rgb(211,211,211)' }}
                            >
                                <MenuButton fontSize='1.1rem'
                                            width='100%'
                                            size='12rem' w={'full'} bg='rgb(247, 248, 250)'
                                            p='1rem 1rem 1.7rem'
                                            borderRadius='1.25rem'
                                            border={`0.2rem solid ${
                                                errors.category ? 'red' : 'rgb(237, 238, 242)'
                                            }`} color={'black'} as={Button} rightIcon={<ChevronDownIcon />}>
                                    <Flex direction={'row-reverse'}>
                                        {categoryWatch && <Text fontSize={20} ml={2}>{t(`filter-categories.${categoryWatch}`)}</Text>}
                                        <Text fontSize={20} fontWeight={450} color={'rgb(156 163 175)'}>{t('me')} *</Text>
                                    </Flex>

                                </MenuButton>
                                <MenuList {...register('category', { required: true })}>
                                    {settings.filterCategories.map(val =>
                                        <MenuItem key={val} onClick={() => {
                                            setValue('subcategory', null);
                                            setValue('category', val);
                                        }}>{t(`filter-categories.${val}`)}</MenuItem>
                                    )}
                                </MenuList>
                            </Menu>}

                            {categoryWatch && <Menu
                                placement={'bottom-end'}
                                my={1}
                                alignItems='center'
                                justifyContent='space-between'
                                bg='rgb(247, 248, 250)'
                                p='1rem 1rem 1.7rem'
                                borderRadius='1.25rem'
                                border={`0.2rem solid ${
                                    errors.subcategory ? 'red' : 'rgb(237, 238, 242)'
                                }`}
                                _hover={{ border: '0.2rem solid rgb(211,211,211)' }}
                            >
                                <MenuButton fontSize='1.1rem'
                                            width='100%'
                                            color={'black'}
                                            size='12rem' w={'full'} bg='rgb(247, 248, 250)'
                                            mt={1}
                                            p='1rem 1rem 1.7rem'
                                            borderRadius='1.25rem'
                                            border={`0.2rem solid ${
                                                errors.subcategory ? 'red' : 'rgb(237, 238, 242)'
                                            }`} color={'black'} as={Button} rightIcon={<ChevronDownIcon />}>
                                    <Flex direction={'row-reverse'}>
                                        {subcategoryWatch && <Text fontSize={20} ml={2}>
                                            {subcategoryWatch ? t(`filter-subcategories.${subcategoryWatch}`).slice(0, 10) :
                                                t(`filter-subcategories.${subcategoryWatch}`)}{subcategoryWatch?.length > 10 ? '...' : ''}
                                        </Text>}
                                        <Text fontSize={20} fontWeight={450} color={'rgb(156 163 175)'}>{t('category')} *</Text>
                                    </Flex>

                                </MenuButton>
                                <MenuList {...register('subcategory', { required: true })}>
                                    {settings.filterSubcategories.map((subcategory, i) =>
                                            <MenuItem key={i}
                                                      onClick={() => setValue('subcategory', subcategory)}>{t(`filter-subcategories.${subcategory}`)}</MenuItem>
                                        )}
                                </MenuList>
                            </Menu>}


                            <Flex
                                my={1}
                                alignItems='center'
                                justifyContent='space-between'
                                bg='rgb(247, 248, 250)'
                                p='1rem 1rem 1.7rem'
                                borderRadius='1.25rem'
                                border={`0.2rem solid ${
                                    errors.title ? 'red' : 'rgb(237, 238, 242)'
                                }`}
                                _hover={{ border: '0.2rem solid rgb(211,211,211)' }}
                            >
                                <Input
                                    fontSize='1.5rem'
                                    width='100%'
                                    size='19rem'
                                    textAlign='right'
                                    bg='rgb(247, 248, 250)'
                                    color={'black'}
                                    outline='none'
                                    border='none'
                                    focusBorderColor='none'
                                    type='text'
                                    minLength={3}
                                    maxLength={70}
                                    autoComplete='off'
                                    placeholder={`${t(
                                        'filter-form.Description'
                                    )}* (3 - 70 char)`}
                                    aria-invalid={errors.title ? 'true' : 'false'}
                                    {...register('title', {
                                        required: true,
                                        minLength: 3,
                                        maxLength: 70
                                    })}
                                />
                            </Flex>

                            <Flex
                                my={1}
                                alignItems='center'
                                justifyContent='space-between'
                                bg='rgb(247, 248, 250)'
                                p='1rem 1rem 1.7rem'
                                borderRadius='1.25rem'
                                border={`0.2rem solid ${
                                    errors.price ? 'red' : 'rgb(237, 238, 242)'
                                }`}
                                _hover={{ border: '0.2rem solid rgb(211,211,211)' }}
                            >
                                <InputGroup>

                                    <Input
                                        fontSize='1.5rem'
                                        width='100%'
                                        size='19rem'
                                        textAlign='right'
                                        bg='rgb(247, 248, 250)'
                                        color={'black'}
                                        outline='none'
                                        border='none'
                                        focusBorderColor='none'
                                        type='number'
                                        autoComplete='off'
                                        placeholder={`${t('price')}*`}
                                        min={settings.settingsFromJson.MIN_DOLLAR_PRICE_FOR_FILTER}
                                        aria-invalid={errors.price ? 'true' : 'false'}
                                        {...register('price', {
                                            required: true,
                                            min: {
                                                value: settings.settingsFromJson.MIN_DOLLAR_PRICE_FOR_FILTER,
                                                message: `Min ${settings.settingsFromJson.MIN_DOLLAR_PRICE_FOR_FILTER}`
                                            }
                                        })}
                                    />


                                    <InputRightElement
                                        color={'black'}
                                        children='$'
                                    />
                                </InputGroup>
                            </Flex>

                            <Box borderRadius={25} p={4} borderColor={'lightgray'} borderWidth={2}>
                                <Flex direction={'row-reverse'}><Text fontSize={'2xl'}
                                                                      color={'black'}>{t('contact')}</Text></Flex>
                                <Flex
                                    my={1}
                                    alignItems='center'
                                    justifyContent='space-between'
                                    bg='rgb(247, 248, 250)'
                                    p='1rem 1rem 1.7rem'
                                    borderRadius='1.25rem'
                                    border={`0.2rem solid ${
                                        errors.contactEmail ? 'red' : 'rgb(237, 238, 242)'
                                    }`}
                                    _hover={{ border: '0.2rem solid rgb(211,211,211)' }}
                                >
                                    <Input
                                        fontSize='1.5rem'
                                        width='100%'
                                        size='19rem'
                                        textAlign='right'
                                        bg='rgb(247, 248, 250)'
                                        color={'black'}
                                        outline='none'
                                        border='none'
                                        focusBorderColor='none'
                                        type='email'
                                        autoComplete='off'
                                        minLength={10}
                                        maxLength={50}
                                        placeholder={`Email (10 - 50 char)`}
                                        aria-invalid={errors.contactEmail ? 'true' : 'false'}
                                        {...register('contactEmail', {
                                            required: !socialWatch,
                                            minLength: 10,
                                            maxLength: 50
                                        })}
                                    />
                                </Flex>

                                <Flex
                                    my={1}
                                    alignItems='center'
                                    justifyContent='space-between'
                                    bg='rgb(247, 248, 250)'
                                    p='1rem 1rem 1.7rem'
                                    borderRadius='1.25rem'
                                    border={`0.2rem solid ${
                                        errors.contactSocial ? 'red' : 'rgb(237, 238, 242)'
                                    }`}
                                    _hover={{ border: '0.2rem solid rgb(211,211,211)' }}
                                >
                                    <Input
                                        fontSize='1.5rem'
                                        width='100%'
                                        size='19rem'
                                        textAlign='right'
                                        bg='rgb(247, 248, 250)'
                                        color={'black'}
                                        outline='none'
                                        border='none'
                                        focusBorderColor='none'
                                        type='text'
                                        autoComplete='off'
                                        placeholder={`Social (10 - 50 char)`}
                                        aria-invalid={errors.contactSocial ? 'true' : 'false'}
                                        {...register('contactSocial', {
                                            required: !emailWatch,
                                            minLength: 10,
                                            maxLength: 50
                                        })}
                                    />
                                </Flex>
                                <Text mx={4} align={'right'} color={'black'}><small>{t('contactHint')}</small></Text>

                            </Box>


                            <Box mt='0.5rem'>
                                <Button
                                    isLoading={loading}
                                    loadingText={t('pleaseWait')}
                                    color='rgb(213, 0, 102)'
                                    bg='rgb(253, 234, 241)'
                                    width='100%'
                                    p='1.62rem'
                                    borderRadius='1.25rem'
                                    type='submit'
                                    _hover={{ bg: 'rgb(251, 211, 225)' }}
                                >
                                    {t('update')}
                                </Button>

                            </Box>
                        </form>

                        <Box m={16} />

                        <form onSubmit={handleSubmitStatusUpdate(changeStatus)}>
                            <Flex
                                my={1}
                                alignItems='center'
                                direction={'column'}
                                gap={10}
                                justifyContent='space-between'
                                bg='rgb(247, 248, 250)'
                                p='1rem 1rem 1.7rem'
                                borderRadius='1.25rem'
                                border={`0.2rem solid rgb(237, 238, 242)`}
                                _hover={{ border: '0.2rem solid rgb(211,211,211)' }}
                            >
                                <RadioGroup>
                                    <Stack>
                                        <Radio borderColor={'lightgray'} {...registerStatusUpdate('status')}
                                               value='0'><Text color={'black'}>Active</Text></Radio>
                                        <Radio borderColor={'lightgray'} value='1' {...registerStatusUpdate('status', {
                                            required: t('required')
                                        })}><Text color={'black'}>Inactive</Text></Radio>
                                    </Stack>
                                    {errorsStatusUpdate.status &&
                                        <Center mt={4}><FormFieldError mt message={errorsStatusUpdate.status.message} />
                                        </Center>}
                                </RadioGroup>

                                <Button isLoading={loading}
                                        loadingText={t('pleaseWait')}
                                        type={'submit'}
                                        color='rgb(213, 0, 102)'
                                        bg='rgb(253, 234, 241)'
                                        width='100%'
                                        p='1.62rem'
                                        borderRadius='1.25rem'
                                        _hover={{ bg: 'rgb(251, 211, 225)' }}>
                                    {t('updateStatus')}
                                </Button>
                            </Flex>
                        </form>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
