import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { Web3Provider } from '@ethersproject/providers';
import { Web3ReactProvider } from '@web3-react/core';
import React, { Suspense } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';
import App from './App';
import './i18n';
import './index.css';
import theme from './theme';
import 'react-tooltip/dist/react-tooltip.css'

const getLibrary = (provider) => {
    const library = new Web3Provider(provider, 'any');
    library.pollingInterval = 15000;
    return library;
};

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={<div>Loading... </div>}>
            <Web3ReactProvider getLibrary={getLibrary}>
                <ChakraProvider theme={theme}>
                    <ColorModeScript
                        initialColorMode={theme.config.initialColorMode}
                    />
                    <Toaster position="top-right" reverseOrder={true} />
                    <App />
                </ChakraProvider>
            </Web3ReactProvider>
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);
