import { Box, Button, Divider, Flex, Heading, Text } from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import Escrow from '../artifacts/contracts/Escrow.sol/Escrow.json';
import { getContract } from '../helpers/contract';
import {
    extractErrorCode,
    getStringOfEnum,
    isValidAddress
} from '../helpers/helper';
import { Table } from './Table';
import { DropDown } from './ui/Dropdown';

const ColumnHeaders = [
    'Address',
    'State',
    'Title',
    'Token',
    'Amount',
    'Deadline',
    'RequestRevisedDeadline',
    'Seller',
    'Buyer',
    'Action'
];

export default function Admin({ factoryContractFrom, listingContract, coinOptions }) {
    const [offset, setOffset] = useState(0);
    const [balances, setBalances] = useState([]);
    const [processedAddresses, setProcessedAddresses] = useState([]);
    const [notProcessedAddresses, setNotProcessedAddresses] = useState([]);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState([]);
    const [escrowAddresses, setEscrowAddresses] = useState([]);
    const [totalEscrows, setTotalEscrows] = useState(undefined);
    const [trustedHandlers, setTrustedHandlers] = useState();
    const [listingTrustedHandler, setListingTrustedHandler] = useState();
    const [trustedToken, setTrustedToken] = useState();
    const [isTrusted, setIsTrusted] = useState(false);
    const [isListingTrusted, setIsListingTrusted] = useState(false);
    const [isTrustedToken, setIsTrustedToken] = useState(false);

    const [withdrawAddress, setWithdrawAddress] = useState();
    const [withdrawTokenAddress, setWithdrawTokenAddress] = useState();
    const [withdrawAmount, setWithdrawAmount] = useState();

    const [fee, setFee] = useState(1); //Default 1 from contract
    const [feeAddress, setFeeAddress] = useState();
    const [currentFee, setCurrentFee] = useState();
    const { chainId, account, activate, deactivate, active, library } =
        useWeb3React();
    const [loading, setLoading] = useState(false);
    const { t, i18n } = useTranslation();

    const ColumnHeadersTranslated = ColumnHeaders.map((head) =>
        t(`column-headers.${head}`)
    );

    useEffect(() => {
        if (factoryContractFrom) {
            callPage();
            getFee();
        }
    }, []);

    useEffect(() => {
        if (factoryContractFrom) {
            callPage();
            getFee();
        }
    }, [offset, factoryContractFrom]);

    useEffect(() => {
        if (escrowAddresses) {
            getEscrowDetailsOfPage();
        }
    }, [escrowAddresses]);

    const callPage = async () => {
        try {
            const paged = await factoryContractFrom.getEscrowDetailsPaging(
                offset
            );
            setTotalEscrows(paged['total'].toString());
            setEscrowAddresses(paged['escrowAddresses']);
        } catch (err) {
            toast.error(extractErrorCode(err, t));
        }
    };

    const getEscrowDetailsOfPage = async () => {
        try {
            let escrows = [];
            for (const eachEscrow of escrowAddresses) {
                const contract = getContract(
                    library,
                    account,
                    Escrow,
                    eachEscrow
                );

                const detail = await contract.getDetails();
                const title = ethers.utils.parseBytes32String(detail['title']);
                const amount = ethers.utils.formatEther(
                    detail['amount'].toString()
                );
                const buyer = detail['buyer'].toString();
                const address = detail['escrowAddress'].toString();

                const tokenAddr = detail['tokenAddress'].toString();
                const deadline = detail['deadline'].toString();
                const requestRevisedDeadline =
                    detail['requestRevisedDeadline'].toString();
                const token = coinOptions
                    .filter((coin) => coin.address === tokenAddr)
                    .map((coin) => coin.name)
                    .at(0);
                const seller = detail['seller'].toString();
                const st = detail['status'];
                const status = getStringOfEnum(st);
                const bal = await contract.getBalance();
                const balance = ethers.utils.formatEther(bal);

                escrows.push({
                    address,
                    status,
                    amount,
                    token,
                    deadline,
                    requestRevisedDeadline,
                    buyer,
                    seller,
                    title,
                    balance,
                    actions: getActions(status, address, buyer, seller)
                });
            }
            setPage(escrows);
        } catch (err) {
            console.log('Admin EXTRACT details error');
            toast.error(extractErrorCode(err, t));
        }
    };

    const getActions = (status, address, buyer, seller) => {
        return (
            <>
                {status !== 'Cancelled' &&
                    status !== 'Complete' &&
                    toFundButtons(address, buyer, seller)}
            </>
        );
    };

    const toFundButtons = (address, buyer, seller) => {
        return (
            <Flex gap={2}>
                <Button
                    isLoading={loading}
                    loadingText={t('pleaseWait')}
                    colorScheme="red"
                    onClick={() => fund(address, buyer)}
                >
                    ReFund Buyer
                </Button>

                <Button
                    isLoading={loading}
                    loadingText={t('pleaseWait')}
                    onClick={() => fund(address, seller)}
                    colorScheme="green"
                >
                    Fund Seller
                </Button>
            </Flex>
        );
    };

    const getTrustedHandlers = async () => {
        try {
            setLoading(true);
            const trusted = await factoryContractFrom.getProcessedHandlers(true);
            setProcessedAddresses(trusted);
            toast.success(t('Successful'));
        } catch (err) {
            console.log('getTrustedHandlers admin');
            toast.error(extractErrorCode(err, t));
        } finally {
            setLoading(false);
        }
    };

    const getNotTrustedHandlers = async () => {
        try {
            setLoading(true);
            const trusted = await factoryContractFrom.getProcessedHandlers(false);
            setNotProcessedAddresses(trusted);
            toast.success(t('Successful'));
        } catch (err) {
            console.log('getNotTrustedHandlers admin');
            toast.error(extractErrorCode(err, t));
        } finally {
            setLoading(false);
        }
    };

    const getFactoryBalance = async () => {
        try {
            setLoading(true);
            let balancesToPush = [];
            console.log(coinOptions);
            for (let i = 0; i < coinOptions.length; i++) {
                const bal = await factoryContractFrom.balanceOf(
                    coinOptions[i].address
                );
                balancesToPush.push({
                    symbol: coinOptions.at(i).symbol,
                    value: ethers.utils.formatEther(bal)
                });
            }
            setBalances(balancesToPush);
            toast.success(t('Successful'));
        } catch (err) {
            console.log('getFactoryBalance admin');
            toast.error(extractErrorCode(err, t));
        } finally {
            setLoading(false);
        }
    };

    const getFee = async () => {
        try {
            const fee = await factoryContractFrom.getFee();
            setCurrentFee(fee.toString());
        } catch (err) {
            console.log('getFee admin');
            toast.error(extractErrorCode(err, t));
        }
    };

    const updateFeePercent = async (fee) => {
        try {
            setLoading(true);
            const updateFee = await factoryContractFrom.updateFeePercent(fee);
            await updateFee.wait();
            toast.success('Fee successfully changed.');
            await getFee();
        } catch (err) {
            console.log('updateFee admin');
            toast.error(extractErrorCode(err, t));
        } finally {
            setLoading(false);
        }
    };

    const addTrustedHandlers = async (trusteds, bool) => {
        try {
            setLoading(true);
            const trustedHand =
                await factoryContractFrom.switchActiveTrustedHandlers(
                    trusteds,
                    bool
                );
            await trustedHand.wait();
            toast.success('Escrow Successfully created.');
        } catch (err) {
            console.log('addTrusted admin');
            toast.error(extractErrorCode(err, t));
        } finally {
            setLoading(false);
        }
    };

    const addListingTrustedHandler = async (trusteds, bool) => {
        try {
            setLoading(true);
            const trustedHand =
                await listingContract.switchTrustedHandlers(
                    trusteds,
                    bool
                );
            await trustedHand.wait();
            toast.success('addListingTrustedHandler successfully changed.');
        } catch (err) {
            console.log('addListingTrustedHandler admin');
            toast.error(extractErrorCode(err, t));
        } finally {
            setLoading(false);
        }
    };


    const addTrustedToken = async (trusteds, bool) => {
        try {
            setLoading(true);
            const trustedHand =
                await factoryContractFrom.switchActiveTrustedTokens(
                    trusteds,
                    bool
                );
            await trustedHand.wait();
            toast.success('Succesful');
        } catch (err) {
            console.log('addtrust token admin');
            toast.error(extractErrorCode(err, t));
        } finally {
            setLoading(false);
        }
    };

    const fund = async (escrowAddess, toFund) => {
        try {
            setLoading(true);
            const escrowContract = getContract(
                library,
                account,
                Escrow,
                escrowAddess
            );
            let delivery;
            //if (chainId === 88 || chainId === 89 || chainId === 31337) { // TODO: TOMO
            //    delivery = await escrowContract.fund(toFund, { gasPrice: 250000000, gasLimit: 1000000 });
            //} else {
                delivery = await escrowContract.fund(toFund);
            //}
            await delivery.wait();
            toast.success(t('Successful'));
        } catch (err) {
            console.log('fund admin');
            toast.error(extractErrorCode(err, t));
        } finally {
            setLoading(false);
        }
    };
    const changeFeeAddress = async (feeAddr) => {
        try {
            setLoading(true);
            const updateFee = await factoryContractFrom.updateFeeAddress(
                feeAddr
            );
            await updateFee.wait();
            toast.success(t('Successful'));
        } catch (err) {
            console.log('changeFeeAdd admin');
            toast.error(extractErrorCode(err, t));
        } finally {
            setLoading(false);
        }
    };

    const checkTrustedToken = async (address) => {
        try {
            setLoading(true);
            const trustedHand = await factoryContractFrom.checkTrustedToken(
                address
            );
            setIsTrustedToken(trustedHand);
        } catch (err) {
            console.log('checkTrustedToken admin');
            toast.error(extractErrorCode(err, t));
        } finally {
            setLoading(false);
        }
    };

    const checkTrustedHandler = async (address) => {
        try {
            setLoading(true);
            const trustedHand = await factoryContractFrom.checkTrusted(address);
            setIsTrusted(trustedHand);
        } catch (err) {
            console.log('checkTrustedToken admin');
            toast.error(extractErrorCode(err, t));
        } finally {
            setLoading(false);
        }
    };

    const checkListingTrustedHandler = async (address) => {
        try {
            setLoading(true);
            const trustedHand = await listingContract.checkTrustedHandler(address);
            setIsListingTrusted(trustedHand);
        } catch (err) {
            console.log('checkListingTrusted admin');
            toast.error(extractErrorCode(err, t));
        } finally {
            setLoading(false);
        }
    }

    const withdraw = async (toAddr, tokenAddr, amount) => {
        try {
            setLoading(true);
            const tknAddr = tokenAddr
                ? tokenAddr
                : ethers.constants.AddressZero;
            const withdrawTsx = await factoryContractFrom.withdraw(
                toAddr,
                tknAddr,
                ethers.utils.parseUnits(amount, 18)
            );
            await withdrawTsx.wait();
            toast.success('Withdraw successfull');
        } catch (err) {
            console.log('err - withdraw admin');
            toast.error(extractErrorCode(err, t));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="pt-2 px-2 space-y-12">
            <div className="space-x-4">
                <Button
                    isLoading={loading}
                    loadingText={t('pleaseWait')}
                    colorScheme="purple"
                    onClick={getFactoryBalance}
                >
                    GET FACTORY BALANCE
                </Button>
                <Button
                    isLoading={loading}
                    loadingText={t('pleaseWait')}
                    colorScheme="purple"
                    onClick={getTrustedHandlers}
                >
                    GET PROCESSED TRUSTED
                </Button>
                <Button
                    isLoading={loading}
                    loadingText={t('pleaseWait')}
                    colorScheme="blue"
                    onClick={getNotTrustedHandlers}
                >
                    GET PROCESSED NOT TRUSTED
                </Button>
            </div>
            {processedAddresses.length ? (
                <Text color={'black'}>
                    TRUSTED ADDRESSES: {JSON.stringify(processedAddresses)}
                </Text>
            ) : (
                <></>
            )}
            {notProcessedAddresses.length ? (
                <Text color={'black'}>
                    NOT TRUSTED ADDRESSES:{' '}
                    {JSON.stringify(notProcessedAddresses)}
                </Text>
            ) : (
                <></>
            )}
            <Text color={'black'}>
                FACTORY BALANCE: {JSON.stringify(balances)}
            </Text>
            <Box>
                <Table columnHeaders={ColumnHeadersTranslated} escrows={page} />
            </Box>
            <div className="flex-column w-full mx-auto">
                <div className="flex flex-col items-center">
                    <span className="text-sm text-gray-700 dark:text-gray-400">
                        Showing{' '}
                        <span className="font-semibold text-gray-900 dark:text-white">
                            {offset + 1}
                        </span>{' '}
                        to{' '}
                        <span className="font-semibold text-gray-900 dark:text-white">
                            {limit}
                        </span>{' '}
                        of{' '}
                        <span className="font-semibold text-gray-900 dark:text-white">
                            {totalEscrows}
                        </span>{' '}
                        Entries
                    </span>

                    <DropDown
                        head={'PAGE'}
                        onClick={(page) => setOffset((page - 1) * limit)}
                        elements={Array.from(
                            { length: Math.ceil(totalEscrows / limit) },
                            (_, i) => i + 1
                        )}
                    />

                    <div className="inline-flex xs:mt-0 space-x-1">
                        <button
                            disabled={offset <= 0}
                            onClick={() => setOffset((prev) => prev - limit)}
                            className="py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-l hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                            Prev
                        </button>
                        <button
                            disabled={offset + limit >= totalEscrows}
                            onClick={() => setOffset((prev) => prev + limit)}
                            className="py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-r border-0 border-l border-gray-700 hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>

            <Flex direction={'column'} gap={2} p={2}>
                <Flex
                    direction={{ base: 'column', sm: 'row' }}
                    mt={8}
                    p={2}
                    gap={2}
                    border={'0.02rem solid black'}
                >
                    <label
                        htmlFor="withdraw"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                        Withdraw to Address with TokenAddress
                    </label>
                    <input
                        id="withdraw"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Withdraw Address"
                        onChange={(e) => setWithdrawAddress(e.target.value)}
                    />
                    <input
                        id="tokenAddr"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Token Address"
                        onChange={(e) =>
                            setWithdrawTokenAddress(e.target.value)
                        }
                    />
                    <input
                        type="number"
                        id="withdrawAmount"
                        min={0}
                        step="any"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Amount of Withdraw"
                        onChange={(e) => setWithdrawAmount(e.target.value)}
                    />
                </Flex>
                <Button
                    isLoading={loading}
                    loadingText={t('pleaseWait')}
                    colorScheme={'purple'}
                    disabled={
                        loading ||
                        !isValidAddress(withdrawAddress) ||
                        !withdrawAmount
                    }
                    onClick={() =>
                        withdraw(
                            withdrawAddress,
                            withdrawTokenAddress,
                            withdrawAmount
                        )
                    }
                >
                    Withdraw
                </Button>
                <Flex
                    direction={{ base: 'column', sm: 'row' }}
                    p={2}
                    mt={8}
                    gap={2}
                    border={'0.02rem solid black'}
                >
                    <label
                        htmlFor="fee"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                        Current fee: {currentFee} Change fee to
                    </label>
                    <input
                        type="number"
                        min={0}
                        max={100}
                        step={1}
                        value={fee}
                        id="fee"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Fee to change"
                        onChange={(e) => setFee(e.target.value)}
                    />
                    <Button
                        isLoading={loading}
                        loadingText={t('pleaseWait')}
                        colorScheme={'purple'}
                        disabled={loading || fee < 0 || fee > 100 || !fee}
                        onClick={() => updateFeePercent(Number(fee))}
                    >
                        Submit Fee
                    </Button>
                </Flex>

                <Flex
                    direction={{ base: 'column', sm: 'row' }}
                    p={4}
                    mt={8}
                    gap={2}
                    border={'0.02rem solid black'}
                >
                    <label
                        htmlFor="toFeeAddress"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                        TO Fee Address
                    </label>
                    <input
                        type="text"
                        id="toFeeAddress"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Adress to send fee"
                        onChange={(e) => setFeeAddress(e.target.value)}
                    />
                </Flex>
                <Button
                    isLoading={loading}
                    loadingText={t('pleaseWait')}
                    colorScheme={'blue'}
                    disabled={loading || !isValidAddress(feeAddress)}
                    onClick={() => changeFeeAddress(feeAddress)}
                >
                    Change To Fee Address
                </Button>

                <Flex
                    direction={{ base: 'column', sm: 'row' }}
                    p={2}
                    mt={8}
                    gap={2}
                    border={'0.02rem solid black'}
                >
                    <label
                        htmlFor="trustedHandler"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                        {isTrusted ? 'true' : 'false'} - Trusted Handler
                    </label>
                    <input
                        type="text"
                        id="trustedHandler"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="TrustedHandler"
                        onChange={(e) => setTrustedHandlers(e.target.value)}
                    />
                </Flex>
                <Button
                    disabled={loading || !isValidAddress(trustedHandlers)}
                    onClick={() => checkTrustedHandler(trustedHandlers)}
                    isLoading={loading}
                    loadingText={t('pleaseWait')}
                    colorScheme={'green'}
                >
                    Check Trusted Handler
                </Button>
                <Button
                    disabled={loading || !isValidAddress(trustedHandlers)}
                    onClick={() => addTrustedHandlers([trustedHandlers], true)}
                    isLoading={loading}
                    loadingText={t('pleaseWait')}
                    colorScheme={'blue'}
                >
                    Add Trusted
                </Button>
                <Button
                    disabled={loading || !isValidAddress(trustedHandlers)}
                    onClick={() => addTrustedHandlers([trustedHandlers], false)}
                    isLoading={loading}
                    loadingText={t('pleaseWait')}
                    colorScheme={'red'}
                >
                    Disable Handler
                </Button>
                <Flex
                    direction={{ base: 'column', sm: 'row' }}
                    p={2}
                    mt={8}
                    gap={2}
                    border={'0.02rem solid black'}
                >
                    <label
                        htmlFor="switchActiveTrustedTokens"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                        {isTrustedToken ? 'true' : 'false'} - Trusted Token
                    </label>
                    <input
                        type="text"
                        id="trustedTokens"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="TrustedTokens"
                        onChange={(e) => setTrustedToken(e.target.value)}
                    />
                </Flex>
                <Button
                    disabled={loading || !isValidAddress(trustedToken)}
                    onClick={() => checkTrustedToken(trustedToken)}
                    isLoading={loading}
                    colorScheme={'green'}
                    loadingText={t('pleaseWait')}
                >
                    Check Trusted Token
                </Button>
                <Button
                    disabled={loading || !isValidAddress(trustedToken)}
                    onClick={() => addTrustedToken([trustedToken], true)}
                    isLoading={loading}
                    loadingText={t('pleaseWait')}
                    colorScheme={'blue'}
                >
                    Add Trusted Token
                </Button>
                <Button
                    disabled={loading || !isValidAddress(trustedToken)}
                    onClick={() => addTrustedToken([trustedToken], false)}
                    isLoading={loading}
                    loadingText={t('pleaseWait')}
                    colorScheme={'red'}
                >
                    Disable Token
                </Button>
            </Flex>
            <Divider />
            <Heading>Escrow Filters</Heading>
            <Flex direction={'column'} gap={2} p={2}>

                <Flex
                    direction={{ base: 'column', sm: 'row' }}
                    p={2}
                    mt={4}
                    gap={2}
                    border={'0.02rem solid black'}
                >
                    <label
                        htmlFor='switchListingTrustedHandlers'
                        className='block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                    >
                        {isListingTrusted ? 'true' : 'false'} - Listing Trusted Handler
                    </label>
                    <input
                        type='text'
                        id='listingTrustedHandlers'
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                        placeholder='ListingTrustedHandlers'
                        onChange={(e) => setListingTrustedHandler(e.target.value)}
                    />
                </Flex>
                <Button
                    disabled={loading || !isValidAddress(listingTrustedHandler)}
                    onClick={() => checkListingTrustedHandler(listingTrustedHandler)}
                    isLoading={loading}
                    colorScheme={'green'}
                    loadingText={t('pleaseWait')}
                >
                    Check Listing Trusted Handler
                </Button>
                <Button
                    disabled={loading || !isValidAddress(listingTrustedHandler)}
                    onClick={() => addListingTrustedHandler([listingTrustedHandler], true)}
                    isLoading={loading}
                    loadingText={t('pleaseWait')}
                    colorScheme={'blue'}
                >
                    Add Listing Trusted Handler
                </Button>
                <Button
                    disabled={loading || !isValidAddress(listingTrustedHandler)}
                    onClick={() => addListingTrustedHandler([listingTrustedHandler], false)}
                    isLoading={loading}
                    loadingText={t('pleaseWait')}
                    colorScheme={'red'}
                >
                    Disable Listing Handler
                </Button>
            </Flex>
        </div>
    );
}
